import React, { useState, useRef, useEffect } from "react";
import "../CSS/imageselection.css";

const ImageSelections = (props) => {
  console.log(props.imgtag);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });
  const [rectangle, setRectangle] = useState(null);
  const imageRef = useRef(null);

  const handleMouseDown = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setStartPoint({ x, y });
    setEndPoint({ x, y });
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (x < 0 || y < 0 || x > rect.width || y > rect.height) {
      return;
    }
    setEndPoint({ x, y });
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      if (startPoint.x !== endPoint.x && startPoint.y !== endPoint.y) {
        setRectangle({ startPoint, endPoint });
        const maskPromptValue = `${startPoint.x},${startPoint.y},${endPoint.x},${endPoint.y}`;
        props.setmaskprompt(maskPromptValue);
      }
      setIsDrawing(false);
    }
  };

  const getRectangleStyle = (start, end) => {
    const left = Math.min(start.x, end.x);
    const top = Math.min(start.y, end.y);
    const width = Math.abs(start.x - end.x);
    const height = Math.abs(start.y - end.y);

    return { left, top, width, height };
  };

  useEffect(() => {
    const handleMouseUpGlobal = () => {
      if (isDrawing) {
        handleMouseUp();
      }
    };

    window.addEventListener("mouseup", handleMouseUpGlobal);
    return () => {
      window.removeEventListener("mouseup", handleMouseUpGlobal);
    };
  }, [isDrawing]);

  return (
    <div
      className="image-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <img
        ref={imageRef}
        src={props.imgtag}
        alt="Selection"
        className="image"
      />
      {rectangle && (
        <div
          className="rectangle"
          style={getRectangleStyle(rectangle.startPoint, rectangle.endPoint)}
        ></div>
      )}
      {isDrawing && (
        <div
          className="rectangle"
          style={getRectangleStyle(startPoint, endPoint)}
        ></div>
      )}
      {/* <div className="coordinates">
        {rectangle && (
          <div className="text-point">
            [ {rectangle.startPoint.x}, {rectangle.startPoint.y},{" "}
            {rectangle.endPoint.x}, {rectangle.endPoint.y}]
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ImageSelections;
