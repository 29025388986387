import React, { useEffect, useState } from "react";
import { Col, Row, Input, Card, DatePicker, Button, message, Modal, Table } from "antd";
import { Icon } from "@iconify/react";
import TokenInput from "./TokenInput"
import '../CSS/Token.css';
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import Tokencreate from "./Tokencreate";
import TokenAgent from "./TokenAgent";
import { tokencreditlist, tokencreditupdate, tokencreditlog } from "../../api/accountAction";
import { useDispatch } from "react-redux";
import { simplifyNumber } from "../../customhook/CustomHoom";
import dayjs from "dayjs";

const Tokenmain = () => {
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );
    const userName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );
    const dispatch = useDispatch();
    const [newview, setNewview] = useState(false);
    const [isEditable, setIsEditable] = useState();
    const [isEnough, setIsEnough] = useState(false);
    const [loghistory, setLoghistory] = useState(false);
    const [updatestatus, setupdatestatus] = useState(false)
    const [update, setUpdate] = useState([])
    const [save, setSave] = useState(false)
    const [viewdetails, setViewdetails] = useState([])
    const [months, setMonths] = useState([])
    const [previousvalue, setpreviousvalue] = useState()
    const [actualcredit, setActualcredit] = useState();
    const [totalCredits, setTotalCredits] = useState();
    const [year, setYear] = useState(new Date().getFullYear())
    const [inputValues, setInputValues] = useState(months.map(month => month.value));
    const [logdata, setLogdata] = useState([])
    const onChange = (date, dateString) => {
        if (date) {
            setYear(date.$y);
        } else {
            setYear(new Date().getFullYear());
        }
    };

    const calculateTotalCredits = () => {
        const total = months.reduce((acc, month) => {
            const value = parseInt(month.value, 10) || 0;
            return acc + value;
        }, 0);
        const diff = actualcredit - total
        if (diff < 0) {
            message.error("Insufficient tokens allocated");
            setIsEnough(true)
        } else {
            setIsEnough(false)
        }
        // setTotalCredits(diff);
    };
    useEffect(() => {
        calculateTotalCredits();
    }, [isEditable]);
    const handleviewclick = (index) => {
        const index_details = {
            year: year,
            month_index: index + 1,
            month_details: months[index],
        }
        setViewdetails(index_details)
        setNewview(true)
    }
    const handleEditClick = (index) => {
        // console.log(index)
        // console.log(months[index].total_tokens)
        setpreviousvalue(months[index].total_tokens)
        setIsEditable(index);
    };
    const handleSaveClick = (index) => {
        // console.log(previousvalue)
        const update = {
            companyName: companyName,
            year: year,
            month_index: index + 1,
            pre: previousvalue,
            current: inputValues[index],
            user_id: userName,
        }
        // setSave(true)
        setUpdate(update)
        dispatch(tokencreditupdate(update))
            .unwrap()
            .then(({ data }) => {
                const result = JSON.parse(data.aivolvex_control_plane);
                message.success("Successfully updated")
                setSave(!save)
            })
            .catch((err) => {
                console.log(err);
            });
        setIsEditable(false);
    };
    useEffect(() => {
        dispatch(tokencreditlist({ companyName, year }))
            .unwrap()
            .then(({ data }) => {
                const inputData = JSON.parse(data.aivolvex_control_plane);
                // console.log(inputData)
                const monthNames = [
                    "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                const transformData = (data) => {
                    const transformedData = [];
                    for (let month = 1; month <= 12; month++) {
                        const foundItem = data.find(item => item.month === month);
                        if (foundItem) {
                            const monthName = monthNames[month - 1];
                            transformedData.push({
                                ...foundItem,
                                "remaining_tokens": foundItem.available_tokens,
                                "monthname": monthName
                            });
                        } else {
                            const monthName = monthNames[month - 1];
                            transformedData.push({
                                "total_tokens": 0,
                                "remaining_tokens": 0,
                                "available_tokens": 0,
                                "year": 2023,
                                "month": month,
                                "monthname": monthName
                            });
                        }
                    }
                    return transformedData;
                };
                const transformedData = transformData(inputData);

                setMonths(transformedData)
                inputData.forEach(data => {
                    const { month, available_tokens, total_tokens } = data;
                    const monthIndex = month - 1;

                    if (monthIndex >= 0 && monthIndex < months.length) {
                        const updatedMonths = [...months];
                        updatedMonths[monthIndex].remaining_tokens = available_tokens;
                        updatedMonths[monthIndex].total_tokens = total_tokens;
                        setMonths(updatedMonths);
                    }
                });
                const totalAvailableTokens = inputData.reduce((total, data) => total + data.available_tokens, 0);
                const totalTotalTokens = inputData.reduce((total, data) => total + data.total_tokens, 0);
                setTotalCredits(totalTotalTokens)
                setActualcredit(totalAvailableTokens)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [year, save, updatestatus])

    const handleValueChange = (index, newValue) => {
        // console.log(newValue)
        const previousValue = months[index].total_tokens;
        // console.log(previousValue)
        // setpreviousvalue(previousValue)
        const difference = newValue - previousValue;
        setTotalCredits(totalCredits + difference)
        setActualcredit(actualcredit + difference)
        const newInputValues = [...inputValues];
        newInputValues[index] = newValue;
        // console.log(newInputValues)
        setInputValues(newInputValues);
        const updatedMonths = [...months];
        updatedMonths[index].total_tokens = newValue;
        setMonths(updatedMonths);
    };

    const handlelog = (index) => {
        setLoghistory(true)
        const update = {
            companyName: companyName,
            year: year,
            month_index: index + 1,
            user_id: userName,
        }
        dispatch(tokencreditlog(update))
            .unwrap()
            .then(({ data }) => {
                const result = JSON.parse(data.aivolvex_control_plane);
                // console.log(result);
                setLogdata(result)
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleCancel = () => {
        setLoghistory(false)
    }
    const monthFormat = "YYYY";
    const formattedDate = `${year}`;

    return (
        <>
            {newview ? (<>
                {/* <Tokencreate /> */}
                <TokenAgent totalCredits={totalCredits} actualcredit={actualcredit} newview={newview} setNewview={setNewview} viewdetails={viewdetails} months={months} setupdatestatus={setupdatestatus} updatestatus={updatestatus} />
            </>) : (<>
                <div className="pro-body">
                    <div className="pro-sub-body">
                        <br />
                        <Row span={24} gutter={[16, 16]}>
                            <Col xs={24}
                                sm={24}
                                md={24}
                                lg={14}
                                xl={14}>
                                <h1 className="body-title">Assign Credits</h1>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={5}
                                xl={5} >
                                <div className="agent-header-button-design credit-button" style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Actual Credits : {simplifyNumber(actualcredit)}</span>
                                    <img
                                        src={aivolvex}
                                        alt="Image"
                                        className="token-nav-logo aivolvex-font credit-image "
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={5}
                                xl={5} >
                                <div className="agent-header-button-design credit-button" style={{ display: 'flex', alignItems: 'center' }}>
                                    <span> Allocated Credits : {simplifyNumber(totalCredits)}</span>
                                    <img
                                        src={aivolvex}
                                        alt="Image"
                                        className="token-nav-logo aivolvex-font credit-image "
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row span={24}>
                            <Col className="token-cal"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={4}
                                xl={4}>
                                <DatePicker
                                    onChange={onChange}
                                    picker="year"
                                    defaultValue={dayjs(formattedDate, monthFormat)}
                                    format={monthFormat} />
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={[24, 24]}>
                            <Row gutter={[24, 24]}>
                                {months.map((month, index) => (
                                    <Col
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        lg={4}
                                        xl={4}
                                        key={index}>
                                        <div className="aivolvex-dashboard-outline-setup token-border">
                                            <Card className="token-card-main">

                                                <Row className="token-card-title-text">
                                                    <div style={{ textAlign: 'center' }}>{month.monthname}</div>
                                                    <div>
                                                        <Button className="agent-icon-button" onClick={() => { handlelog(index) }}>
                                                            <Icon icon="subway:refresh-time" className="agent-icon" />
                                                        </Button>
                                                    </div>
                                                </Row>

                                                <br />
                                                <Row>
                                                    <Col span={18} className="token-button">
                                                        {isEditable === index ? (
                                                            <Button
                                                                className="token-card token-first"
                                                                style={{ display: 'flex', alignItems: 'center' }}
                                                            >
                                                                <TokenInput
                                                                    index={index}
                                                                    months={months}
                                                                    handleValueChange={handleValueChange} />
                                                                <div style={{ flex: 1 }}></div>
                                                                <img src={aivolvex} alt="Image" className="token-nav-logo aivolvex-font" />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="token-card token-first"
                                                                style={{ display: 'flex', alignItems: 'center' }}
                                                            >
                                                                <span>{simplifyNumber(month.total_tokens)}</span>
                                                                <div style={{ flex: 1 }}></div>
                                                                <img src={aivolvex} alt="Image" className="token-nav-logo aivolvex-font" />
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col span={6} className="token-button">
                                                        {isEditable === index ? (
                                                            <Button className="token-card token-success save-button-token" onClick={() => handleSaveClick(index)} disabled={isEnough}>
                                                                <Icon icon="mdi:tick" />
                                                            </Button>
                                                        ) : (
                                                            <Button className="token-card token-success" onClick={() => handleEditClick(index)}>
                                                                <Icon icon="wpf:edit" />
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col span={24} className="token-button ">
                                                        <div className="token-card token-first token-credits-text" onClick={() => handleviewclick(index)}>
                                                            View Agents
                                                        </div>
                                                    </Col>
                                                    <Col span={24} className="token-button">
                                                        <div className="credit-text">
                                                            Actual credits : {simplifyNumber(month.remaining_tokens)} </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <br />
                        </Row>
                    </div>
                </div>
            </>)}
            <Modal title="Log" open={loghistory} onCancel={handleCancel} footer={false}>
                {logdata.map((log, index) => {
                    // Convert the log.date_time string to a JavaScript Date object
                    const date = new Date(log.date_time);

                    // Format the date in the desired format
                    const formattedDate = date.toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });

                    return (
                        <div key={index}>
                            <Row gutter={[16, 16]} className="log-body">
                                <Row className="text-body-log log-body">
                                    <Col>
                                        <Row span={24}>
                                            <Col

                                                xs={10}
                                                sm={10}
                                                md={12}
                                                lg={4}
                                                xl={10} className="text-right-text"> Date and Time:</Col>
                                            <Col xs={14}
                                                sm={14}
                                                md={12}
                                                lg={4}
                                                xl={14} className="text-left-text">{formattedDate}</Col>
                                        </Row>
                                        <Row span={24}>
                                            <Col xl={10} sm={4} xs={4} className="text-right-text"> User:</Col>
                                            <Col xl={14} sm={20} xs={20} className="text-left-text"> {log.user_id}</Col>
                                        </Row>
                                        <Row span={24}>
                                            <Col xl={10} sm={5} xs={8} className="text-right-text"> Command:</Col>
                                            <Col xl={14} sm={19} xs={16} className="text-left-text"> {log.token_value}</Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Row>
                            <br />
                        </div>
                    );
                })}
            </Modal>

        </>
    )
}
export default Tokenmain