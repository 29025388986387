import React, { useState, useEffect } from "react";
import { Col, Row, Popconfirm } from "antd";
import "../CSS/dashboardnew.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  getmodalchart,
  gettokenagent,
  gettopuser,
  getyearusage,
} from "../../api/accountAction";
import ModelCredits from "./ModelCredits";
import { Icon } from "@iconify/react";
import { simplifyNumber } from "../../customhook/CustomHoom";

// function simplifyNumber(number) {
//   const billion = 1000000000;
//   const million = 1000000;
//   const thousand = 1000;

//   if (number >= billion) {
//     return (number / billion).toFixed(1) + 'B';
//   } else if (number >= million) {
//     return (number / million).toFixed(1) + 'M';
//   } else if (number >= thousand) {
//     return (number / thousand).toFixed(1) + 'K';
//   } else {
//     return number.toString();
//   }
// }

const Usagetrend = () => {
  const dispatch = useDispatch();
  const { dashbaorddate, offerstatus } = useSelector((state) => state.chat);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const [labelchart, setLabelChart] = useState([]);
  const [numericvalue, setNumericValue] = useState([]);
  const [labelagent, setLabelAgent] = useState([]);
  const [numericagent, setNumericAgent] = useState([]);
  const [labeltopuser, setLabelTopUser] = useState([]);
  const [numerictopuser, setNumericTopUser] = useState([]);
  const [labelyearusage, setLabelYearUsage] = useState([]);
  const [numericyearusage, setNumericYearUsage] = useState([]);
  const [allocatedtoken, setAllocatedtoken] = useState([]);

  const date = new Date(dashbaorddate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  useEffect(() => {
    const avatarmonthwise = {
      event_type: "avatar_token_monthwise",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };
    dispatch(gettokenagent(avatarmonthwise))
      .unwrap()
      .then(({ data }) => {
        const avatarcredit = JSON.parse(data.aivolvex_control_plane);
        const currentDateValue = new Date(dashbaorddate);
        const currentYear = currentDateValue.getFullYear();
        const currentMonth = (currentDateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");

        const currentMonthData = avatarcredit.filter((item) => {
          const [year, month] = item.month.split("-");
          return year === String(currentYear) && month === currentMonth;
        });

        if (currentMonthData) {
          currentMonthData.sort(
            (a, b) => b.total_token_used - a.total_token_used
          );
          const top5Data = currentMonthData.slice(0, 5);
          const label_chart = top5Data.map((item) => item.avatar_name);
          setLabelAgent(label_chart);
          const chartdata = top5Data.map((item) => item.total_token_used);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericAgent(numericValues_db);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const top5user = {
      event_type: "top_user_token",
      company: companyName,
      user_id: userName,
      year: year,
      month: month,
    };

    dispatch(gettopuser(top5user))
      .unwrap()
      .then(({ data }) => {
        const gettopuser = JSON.parse(data.aivolvex_control_plane);
        const label_chart = gettopuser.map((item) => item.user_id);
        setLabelTopUser(label_chart);
        const chartdata = gettopuser.map((item) => item.total_tokens);
        const numericValues_db = chartdata.map((value) => parseInt(value, 10));
        setNumericTopUser(numericValues_db);
      })
      .catch((err) => {
        console.log(err);
      });

    const input = {
      event_type: "avatar_token_monthwise",
      company: companyName,
      user_id: userName,
      year: year,
      user_type: offerstatus,
    };

    dispatch(getyearusage(input))
      .unwrap()
      .then(({ data }) => {
        const getyearusage = JSON.parse(data.aivolvex_control_plane);
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const allMonthsData = Array.from({ length: 12 }, (_, i) => {
          const monthData = getyearusage.find((item) => item.month === i + 1);
          return monthData
            ? monthData
            : { month: i + 1, total_tokens: 0, tokens_used: 0 };
        });

        allMonthsData.sort((a, b) => (a.month > b.month ? 1 : -1));

        const labelYearUsage = allMonthsData.map(
          (item) => monthNames[item.month - 1]
        );

        setLabelYearUsage(labelYearUsage);

        const numericValues_db = allMonthsData.map((item) =>
          parseInt(item.total_tokens, 10)
        );
        setNumericYearUsage(numericValues_db);
        const tokenused = allMonthsData.map((item) =>
          parseInt(item.tokens_used, 10)
        );
        setAllocatedtoken(tokenused);
      })
      .catch((err) => {
        console.log(err);
      });

    const questioncount = {
      event_type: "model_wise_question_count",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };

    dispatch(getmodalchart(questioncount))
      .unwrap()
      .then(({ data }) => {
        const modalquestion = JSON.parse(data.aivolvex_control_plane);
        if (modalquestion) {
          const label_chart = modalquestion.map((item) => {
            if (item.model_id) {
              return item.model_id;
            } else {
              return "empty";
            }
          });
          setLabelChart(label_chart);

          const chartdata = modalquestion.map((item) => item.question_count);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericValue(numericValues_db);
        } else {
          console.log("No avatars found.");
        }
      })

      .catch((err) => {
        console.log(err);
      });
  }, [dashbaorddate, offerstatus]);

  const dashboardDate = new Date(dashbaorddate);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[dashboardDate.getMonth()];

  const options_db = {
    chart: {
      type: "pie",
    },
    labels: labelchart,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: ["#D1FFB4", "#FDF7C3", "#FFB4B4", "#FFB4D8", "#65CCD2", "#B2A4FF"],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Model Credits`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  const seriestopuser = numerictopuser;
  const chartDataTopUser = {
    labels: labelchart,
    series: seriestopuser,
  };
  const topuseroptions = {
    chart: {
      type: "pie",
    },
    labels: labeltopuser,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: ["#FFD966", "#FF8B66", "#F4B183", "#F1F7B5", "#A8D1D1", "#9EA1D4"],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Top 5 Users by Credits`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  const series_db = numericvalue;
  const chartData_db = {
    labels: labelchart,
    series: series_db,
  };

  const series = [44, 55, 41, 17, 15];

  const userseries = numericagent;

  const chartdata = {
    labels: labelagent,
    series: userseries,
  };

  const useroptions = {
    chart: {
      type: "pie",
    },
    labels: labelagent,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: ["#B2A4FF", "#FFB4B4", "#65CCD2", "#FDF7C3", "#FFB4D8", "#D0FFB4"],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName}  Month Top 5 Agent by Credits`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  const lineseries = [
    {
      name: "Actual",
      type: "area",
      data: numericyearusage,
      color: "#9dd64d",
    },
    {
      name: "Used",
      type: "line",
      data: allocatedtoken,
      color: "#fff",
    },
  ];
  const lineoptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    series: series,
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      align: "left",
      style: {
        color: "#fff",
        fontWeight: "lighter",
      },
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1],
    },
    labels: labelyearusage,
    markers: {
      size: 0,
    },
    // yaxis: [
    //   {
    //     title: {
    //       text: "Token",
    //       style: {
    //         color: "#ffffff",
    //       },
    //     },
    //     labels: {
    //       style: {
    //         colors: "#42445f",
    //       },
    //     },
    //   },
    // ],
    yaxis: {
      labels: {
        style: {
          // colors: "rgba(255, 255, 255, 0.40)",
          colors: "#fff",
        },
        formatter: (value) => {
          const num = (Math.round(value * 100) / 100).toFixed(2);
          const absNum = Math.abs(num);

          if (absNum >= 1e9) {
            return Math.sign(num) * (absNum / 1e9).toFixed(1) + "B";
          } else if (absNum >= 1e6) {
            return Math.sign(num) * (absNum / 1e6).toFixed(1) + "M";
          } else if (absNum > 999) {
            return Math.sign(num) * (absNum / 1000).toFixed(1) + "K";
          } else {
            return Math.sign(num) * absNum;
          }
        },
      },
    },

    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
    xaxis: {
      title: {
        //   text: 'Month',
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points";
          }
          return y;
        },
      },
    },
  };

  return (
    <>
      <Row className="mt-4">
        <Col span={24} className="d-flex justify-content-center">
          <h4 className="dashboard-credit aivolvex-font">Model Usage Trend</h4>
        </Col>
      </Row>

      {/* <Row>
        <Col span={24} className="d-flex justify-content-center mt-4">
          <h4 className="white-color dashboard-sub-header">Usage Trend</h4>
        </Col>
      </Row> */}
      <Row className="mt-3 d-flex justify-content-between" gutter={16}>
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div id="chart">
                  <ReactApexChart
                    options={options_db}
                    series={chartData_db.series}
                    type="pie"
                    height={234}
                  />
                  <Popconfirm
                    placement="leftTop"
                    title=""
                    description={
                      <PopConfirmTemplate content="This pie chart illustrates the token consumption of all LLM models used on our platform. Each slice represents the relative credit consumption of the specfic LLM model, providing insights into which model are most frequently utilized." />
                    }
                    okText=""
                    cancelText=""
                    cancelButtonProps={{ style: { display: "none" } }}
                    okButtonProps={{ style: { display: "none" } }}
                    className="volvex-about-popconfirm"
                  >
                    <Icon
                      icon="bx:info-square"
                      className="info-icon cursor-pointer"
                    />
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div className="table-usage">
                  <>
                    <ModelCredits />
                    <Popconfirm
                      placement="leftTop"
                      title=""
                      description={
                        <PopConfirmTemplate content="This table provides a breakdown of AI model interactions on our platform, detailing how frequently each model is used and the total credits exchanged with them." />
                      }
                      okText=""
                      cancelText=""
                      cancelButtonProps={{ style: { display: "none" } }}
                      okButtonProps={{ style: { display: "none" } }}
                      className="volvex-about-popconfirm"
                    >
                      <Icon
                        icon="bx:info-square"
                        className="info-icon cursor-pointer"
                      />
                    </Popconfirm>
                    {/* <Table
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      height={500}
                    /> */}
                  </>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-3 d-flex justify-content-between" gutter={16}>
        <div className="aivolvex-dashboard-outline full-width">
          <div className="Dashboard_graph_box Dashboard_head_box_shadow">
            <div className="Dashboard_head_content">
              <div id="chart">
                <ReactApexChart
                  options={lineoptions}
                  series={lineseries}
                  type="line"
                  height={234}
                />
                <Popconfirm
                  placement="leftTop"
                  title=""
                  description={
                    <PopConfirmTemplate content="This graph displays monthly credit actual versus used consumption. Track and compare each month's usage." />
                  }
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                  className="volvex-about-popconfirm"
                >
                  <Icon
                    icon="bx:info-square"
                    className="info-icon cursor-pointer"
                  />
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row
        className="mt-3 d-flex justify-content-between"
        gutter={[16, 16, 16, 16]}
      >
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div id="chart">
                  <ReactApexChart
                    options={topuseroptions}
                    series={chartDataTopUser.series}
                    type="pie"
                    height={234}
                  />
                  <Popconfirm
                    placement="leftTop"
                    title=""
                    description={
                      <PopConfirmTemplate content="Pie chart displays token distribution for the top 5 users, highlighting those with the highest token consumption within the company." />
                    }
                    okText=""
                    cancelText=""
                    cancelButtonProps={{ style: { display: "none" } }}
                    okButtonProps={{ style: { display: "none" } }}
                    className="volvex-about-popconfirm"
                  >
                    <Icon
                      icon="bx:info-square"
                      className="info-icon cursor-pointer"
                    />
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                <div id="chart">
                  <ReactApexChart
                    options={useroptions}
                    series={chartdata.series}
                    type="pie"
                    height={234}
                  />
                  <Popconfirm
                    placement="leftTop"
                    title=""
                    description={
                      <PopConfirmTemplate content="Pie chart showcases the top 5 agents based on total token consumption in the platform, highlighting the highest used AIVolveX Agents" />
                    }
                    okText=""
                    cancelText=""
                    cancelButtonProps={{ style: { display: "none" } }}
                    okButtonProps={{ style: { display: "none" } }}
                    className="volvex-about-popconfirm"
                  >
                    <Icon
                      icon="bx:info-square"
                      className="info-icon cursor-pointer"
                    />
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const PopConfirmTemplate = (props) => {
  return (
    <>
      <div className="volvex-shared-form">
        <p className="aivolvex-dashboard-popconfirm-content white-color">
          {props.content}
        </p>
      </div>
    </>
  );
};

export default Usagetrend;
