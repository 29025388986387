import { Popconfirm, Row, Col, Tabs, Modal, Table, Input, Drawer } from "antd";
import { memo, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import SharedForm from "./SharedForm";
import eye_icon from "../../../assets/icons/eye.svg";
import message_icon from "../../../assets/icons/message.svg";
import star_icon from "../../../assets/icons/star.svg";
import price_icon from "../../../assets/icons/price.svg";
import token_icon from "../../../assets/icons/token.svg";
import chart_icon from "../../../assets/icons/chart.svg";
import { useDispatch, useSelector } from "react-redux";
import LibraryList from "./LibraryList";
import TrendingList from "./TrendingList";
import HistoryList from "./HistoryList";
import { getAvatarS3DocumentList } from "../../api/accountAction";
import { json, useParams } from "react-router-dom";
import { formatDate, formatFileSize } from "../../customhook/CustomHoom";

const ChatRightBar = () => {
  const { avatardetails, chatmessage } = useSelector((state) => state.chat);
  const [open, setOpen] = useState(false);
  const [docviewstatus, setDocViewStatus] = useState(false);
  const { id, conversationId } = useParams();
  const [s3filelist, setS3FileList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const input = {
      avatar_id: id,
      company_name: companyName,
    };
    dispatch(getAvatarS3DocumentList(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setS3FileList(records);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, conversationId, chatmessage]);

  const columns = [
    {
      title: "Document Name",
      dataIndex: "file_name",
      key: "file_name",
      width: "40%",
      align: "left",
      render: (text, index) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                window.open(index.presigned_url, "_blank");
              }}
            >
              {index.file_type === "image/jpeg" ? (
                <Icon
                  icon="flat-color-icons:image-file"
                  style={{ fontSize: "25px", marginRight: "0.7rem" }}
                />
              ) : (
                <Icon
                  icon="vscode-icons:file-type-pdf2"
                  style={{ fontSize: "25px", marginRight: "0.7rem" }}
                />
              )}

              <span>{index.file_name}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "uploaded_datetime",
      key: "uploaded_datetime",
      width: "20%",
      align: "center",
      render: (date, index) => {
        return <>{formatDate(date)}</>;
      },
    },
    {
      title: "Type",
      dataIndex: "file_type",
      key: "file_type",
      width: "20%",
      align: "center",
    },

    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: "20%",
      align: "center",
      render: (value, index) => {
        return <>{formatFileSize(value)}</>;
      },
    },
  ];

  const paginationConfig = {
    pageSize: 5,
  };

  const filteredPrompts = s3filelist?.filter((data) =>
    data.file_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Row className="volvex-chat-right-bar">
        {/* <Col span={24} className="text-center cursor-pointer">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopconfirmSharedDescription />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="solar:share-outline" className="about-header-icon" />
            </div>
            <div className="right-bar-font">Share</div>
          </Popconfirm>
        </Col> */}
        {/* <Col span={24} className="text-center cursor-pointer">
          <div className="volvex-chat-right-bar-border"></div>
        </Col> */}
        <Col span={24} className="text-center cursor-pointer mt-1">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopconfirmAboutDescription />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="ph:info" className="about-header-icon" />
            </div>
            <div className="right-bar-font">Agent Info</div>
          </Popconfirm>
        </Col>
        <Col span={24} className="text-center cursor-pointer mt-3">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopConfirmTemplate type="library" />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="bi:bookmark" className="about-header-icon-library" />
            </div>
            <div className="right-bar-font">Library</div>
          </Popconfirm>
        </Col>
        <Col span={24} className="text-center cursor-pointer mt-3">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopConfirmTemplate type="trending" />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon
                icon="streamline:interface-content-fire-lit-flame-torch-trending"
                className="about-header-icon"
              />
            </div>
            <div className="right-bar-font">Trending</div>
          </Popconfirm>
        </Col>
        <Col span={24} className="text-center cursor-pointer mt-3">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopConfirmTemplate type="history" />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="solar:history-bold" className="about-header-icon" />
            </div>
            <div className="right-bar-font">History</div>
          </Popconfirm>
        </Col>
        {avatardetails.type === "kendra" ||
        avatardetails.type === "image" ||
        avatardetails.type === "prometheus" ||
        avatardetails.type === "knowledgebase" ||
        avatardetails.type === "pgvector" ? (
          <Col span={24} className="text-center cursor-pointer mt-3">
            <div
              onClick={() => {
                setDocViewStatus(true);
              }}
            >
              <div>
                <Icon icon="ph:folder-duotone" className="about-header-icon" />
                {/* <Icon icon="uiw:document" className="about-header-icon" /> */}
              </div>
              <div className="right-bar-font">Folder</div>
            </div>
          </Col>
        ) : null}

        {/* <Col span={24} className="text-center cursor-pointer mt-3">
          <div>
            <Icon icon="heroicons:users" className="about-header-icon" />
          </div>
          <div className="right-bar-font">Shared</div>
        </Col> */}
      </Row>
      <Modal
        title="Document List"
        open={docviewstatus}
        onCancel={() => {
          setDocViewStatus(false);
        }}
        footer={false}
        width="70%"
        className="view-src"
      >
        <div className="d-flex justify-content-end">
          <div className="history-search width-25 ">
            <Input
              placeholder="Search here"
              prefix={
                <Icon
                  icon="material-symbols:search"
                  className="lib-search-icon"
                />
              }
              className="lib-history volvex-search-box"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              bordered={false}
            />
          </div>
        </div>

        <Table
          // className="mt-3 ms-2 source-bootstrap-table"
          className="mt-3 ms-2 costimize-bootstrap-table-pop"
          dataSource={filteredPrompts}
          columns={columns}
          pagination={paginationConfig}
        />
      </Modal>
    </>
  );
};

const PopconfirmSharedDescription = () => {
  return (
    <>
      <div className="volvex-shared-form">
        <Row>
          <Col span={24} className="volvex-shared-form-title-body">
            <label className="volvex-shared-form-title ">
              {" "}
              <Icon
                icon="solar:share-outline"
                className="volvex-info-header-icon"
              />{" "}
              Share With
            </label>
          </Col>
          <Col span={24}>
            {" "}
            <Tabs
              className="aivolvex-bot-tab aivolvex-font"
              items={[
                {
                  key: 1,
                  label: (
                    <>
                      <Icon
                        icon="fluent:tag-20-regular"
                        className="prompt-title-icon"
                      />{" "}
                      Tags
                    </>
                  ),
                  children: <SharedForm type="tag" />,
                  className: "plat-dashboard-link",
                },
                {
                  key: 2,
                  label: (
                    <>
                      <Icon
                        icon="heroicons-outline:user-group"
                        className="prompt-title-icon"
                      />{" "}
                      Members
                    </>
                  ),
                  children: <SharedForm type="member" />,
                  className: "plat-dashboard-link",
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const PopconfirmAboutDescription = () => {
  const [viewallstatus, setViewAllStatus] = useState(true);
  const { avatardetails } = useSelector((state) => state.chat);
  return (
    <>
      <div className="volvex-about-body">
        <Row>
          <Col span={24}>
            <div className="volvex-about-avatar-logo-body">
              <img
                src={avatardetails.avatar_image}
                alt="avatar-img"
                className="volvex-about-avatar-logo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="volvex-about-avatar-title">
              {avatardetails.avatar_name}
            </label>
          </Col>
        </Row>
        <Row>
          {/* <Col span={24} className="d-flex justify-content-center">
            <div className="avatar-info-icons">
              <span>
                <img src={token_icon} alt="token" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">3000</span>
            </div>
            <div className="avatar-info-icons margin-left-1">
              <span>
                <img src={eye_icon} alt="eye" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">5000</span>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <div>
              <div
                className={viewallstatus ? "side-scroll-container-shadow" : ""}
                // className="side-scroll-container-shadow"
              >
                <div class="job-description word-wrap colorSecondary small-text white-color aivolvex-font mt-3 side-scroll-container">
                  {viewallstatus ? (
                    <>
                      {avatardetails.about_us?.length > 380 ? (
                        <>
                          {avatardetails.about_us?.substring(0, 380)}
                          ...{" "}
                        </>
                      ) : (
                        <>{avatardetails.about_us}</>
                      )}
                    </>
                  ) : (
                    <>{avatardetails.about_us}</>
                  )}
                </div>
                <div className="text-right right-padding-2">
                  {viewallstatus && (
                    <>
                      {/* {avatardetails.about_us?.length > 300 && (
                        <> */}
                      <span
                        className="avatar-view-more"
                        onClick={() => setViewAllStatus(false)}
                      >
                        Read more...
                      </span>
                      {/* </>
                      )} */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row className="volvex-about-token-list">
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img
                  src={message_icon}
                  alt="message"
                  className="avatar-info-img"
                />
              </span>
              <span className="info-icon-text">4200</span>
            </div>
          </Col>
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={star_icon} alt="star" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">4.8/5</span>
            </div>
          </Col>
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={price_icon} alt="price" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">$12000</span>
            </div>
          </Col>
          <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={chart_icon} alt="chart" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">950</span>
            </div>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

const PopConfirmTemplate = (props) => {
  return (
    <>
      <div className="volvex-shared-form">
        <Row>
          <Col span={24} className="volvex-shared-form-title-body">
            <label className="volvex-shared-form-title ">
              {props.type === "library" && (
                <>
                  <Icon
                    icon="bi:bookmark"
                    className="volvex-info-header-icon"
                  />
                  Library
                </>
              )}
              {props.type === "trending" && (
                <>
                  <Icon
                    icon="streamline:interface-content-fire-lit-flame-torch-trending"
                    className="volvex-info-header-icon"
                  />
                  Trending
                </>
              )}
              {props.type === "history" && (
                <>
                  <Icon
                    icon="solar:history-bold"
                    className="volvex-info-header-icon"
                  />
                  History
                </>
              )}
            </label>
          </Col>
          <Col span={24}>
            {props.type === "library" && (
              <>
                <LibraryList />
              </>
            )}
            {props.type === "trending" && (
              <>
                <TrendingList />
              </>
            )}
            {props.type === "history" && (
              <>
                <HistoryList />
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(ChatRightBar);
