import { memo, useEffect, useState } from "react";
import {
    Col,
    Row,
    Space,
    Table,
    Pagination,
    Button,
    Popconfirm,
    Input,
    Dropdown
} from "antd";
import {
    getPageination,
    getRecentPrompts,
} from "../../../components/api/accountAction";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoTrashBin } from "react-icons/io5";
import "../CSS/recentprompts.css";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { setLibraryQuestion } from "../../Slice/reducer/chatReducer";
const Recentpromptmobiletable = () => {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [count, setCount] = useState("");
    const [record, setRecord] = useState([]);
    const [preloaderstatus, setPreloaderStatus] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );
    const userName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );
    const formatDate = (inputDate) => {
        // console.log(inputDate)
        if (inputDate) {
            const date = new Date(inputDate);
            const month = date.toLocaleString("default", { month: "short" });
            const day = date.getDate();
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const amOrPm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12;
            const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes
                .toString()
                .padStart(2, "0")} ${amOrPm}`;
            return formattedDate;
        }
    };
    const columns = [
        {
            title: "Pod",
            dataIndex: "pod",
            key: "pod",

        },
        {
            title: "Agent",
            dataIndex: "avatar_name",
            key: "avatar_name",
            render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
        },
        {
            title: "Prompt",
            dataIndex: "prompt",
            key: "Prompt",
        },
        {
            title: "Date and Time",
            dataIndex: "dateandtime",
            key: "dateandtime",

            render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{formatDate(text)}</span>,
        },
        {
            title: (
                <div>
                    <img
                        src={aivolvex}
                        alt="Image"
                        className="token-footer-logo aivolvex-font"
                    />{" "}
                    Credits
                </div>
            ),
            dataIndex: "token",
            key: "token",
        },

        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: "center",


            render: (text, record) => (
                <Space size="middle">
                    <span>
                        <Button
                            className="his-button history_btn"
                            onClick={() => handleIconClick(record)}
                        >
                            <Icon
                                icon="gg:link"
                                style={{ fontSize: "25px", color: "#fff" }}
                            />
                        </Button>
                    </span>
                    <span>
                        <Popconfirm
                            title="Delete Chat?"
                            onConfirm={() => handleDeleteClick(record)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            {/* <Button className="his-button his-delete"><IoTrashBin style={{ fontSize: "18px" }} /> <span className="his-delete-text">Delete</span></Button> */}
                        </Popconfirm>
                    </span>
                </Space>
            ),
        },
    ];
    const handleIconClick = (record) => {
        dispatch(setLibraryQuestion(record.prompt));
        const avatarId = record.avatar_id;

        navigate(`/conversation/${avatarId}`);
    };
    const handleDeleteClick = (record) => {
        // console.log(record);
    };
    const cancel = (e) => {
        // console.log(e);
    };
    const input = {
        user_id: userName,
        company: companyName,
    };
    useEffect(() => {
        dispatch(getPageination(input))
            .unwrap()
            .then(({ data }) => {
                const value = JSON.parse(data.aivolvex_control_plane)[0];
                setCount(value);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const filteredPrompts = record.filter((item) =>
        item.prompt.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        setPreloaderStatus(true);
        const inputlist = {
            page: currentPage,
            limit: pageSize,
            user_id: userName,
            company: companyName,
        };
        dispatch(getRecentPrompts(inputlist))
            .unwrap()
            .then(({ data }) => {
                const value = JSON.parse(data.aivolvex_control_plane);
                setPreloaderStatus(false);
                const table = value.map((datas) => {
                    return {
                        pod: datas.pod,
                        dateandtime: datas.timestamp,
                        avatar_name: datas.avatar_name,
                        prompt: datas.question,
                        token: datas.token_used,
                        avatar_id: datas.avatar_id,
                        session_id: datas.session_id,
                    };
                });
                setRecord(table);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentPage]);
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };
    return (
        <>
            <div className="recent-mobile-view-table">
                <Table
                    className="mobile-view-table"
                    dataSource={filteredPrompts}
                    columns={columns}
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: count,
                        onChange: handlePageChange,
                    }}
                />
            </div>

        </>
    )
}
export default Recentpromptmobiletable;