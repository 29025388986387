import { useEffect, useState } from "react";
import { Table, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { historyrecordviewlist } from "../../api/accountAction";
import { Icon } from "@iconify/react";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";

const JDTranscript = (props) => {
  console.log(props);
  const user_session = props.record.conversation_id;
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  function formatDateHistory(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const isCurrentWeek =
      date >= currentDate && date - currentDate <= 7 * 24 * 60 * 60 * 1000;
    if (isCurrentWeek) {
      const options = {
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    } else {
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }
  }
  useEffect(() => {
    const input = {
      event_type: "public_agent_user_session",
      user_session: user_session,
    };
    setPreloaderStatus(true);
    dispatch(historyrecordviewlist(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const table = value.map((datas) => {
          return {
            date_time: datas.datetime,
            question: datas.question,
            answer: datas.answer,
            handed_off: datas.hand_off,
          };
        });
        setPreloaderStatus(false);
        setTablerecord(table);
      })
      .catch((err) => {
        setPreloaderStatus(false);
      });
  }, [user_session]);
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <div>
            {tablerecord.map((item, index) => (
              <Row className="history-tab-row">
                <Col span={24}>
                  <Row>
                    <Col span={1}></Col>
                    <Col span={23} className="white-font">
                      {" "}
                      {/* {item.question} */}
                      <pre className="aivolvex-font content-formate">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.question,
                          }}
                        />
                      </pre>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={1}></Col>
                    <Col span={23} className="puple-font">
                      {" "}
                      {formatDateHistory(item.date_time)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
        </>
      )}
    </>
  );
};
export default JDTranscript;
