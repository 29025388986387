import { Col, Table, Tag, Space, Modal, Tooltip, Popconfirm, Row } from "antd";
import { memo, useEffect, useState } from "react";
import {
  capitalizeWords,
  formatDatewithtime,
} from "../../customhook/CustomHoom";
import { historyrecordlist } from "../../api/accountAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PopConfirmJDQa from "./PopConfirmJDQa";
import { Icon } from "@iconify/react";
import Historymodal from "./Historymodal";
const JDrecords = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [historyopen, setHistoryOpen] = useState(false);
  const [records, setRecords] = useState([]);
  useEffect(() => {
    const input = {
      event_type: "public_agent_history",
      id: id,
    };
    dispatch(historyrecordlist(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const table = value.map((datas) => {
          return {
            service: datas.service,
            category: datas.category,
            language: datas.language,
            name: datas.user_name,
            email: datas.email,
            handed_off: datas.handed_off,
            typeofconversation: datas.type_of_conversation,
            handed_off_explanation: datas.handed_off_explanation,
            phone: datas.mobile,
            datetime: datas.date_time,
            sentiment: datas.sentiment,
            lead_explanation: datas.lead_explanation,
            sentiment_explanation: datas.sentiment_explanation,
            conversation_id: datas.conversation_id,
            summary: datas.summary,
            lead: datas.lead,
            action_to_be_taken: datas.action_to_be_taken,
            email_content: datas.email_content,
            url: datas.url,
            job_description: datas.job_description,
            selection_status: datas.selection_status,
            qa_status: datas.qa_status,
            score: datas.score,
            qa_content: datas.qa_content,
            voice_url: datas.voice_url,
          };
        });
        setTablerecord(table);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  const formatDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedDate = `${day}-${month}- ${year}, ${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amOrPm}`;
      return formattedDate;
    }
  };
  const filteredRecords = tablerecord.filter(
    (record) => record.typeofconversation === "email_chat"
  );
  const columns = [
    {
      title: "Date&Time",
      dataIndex: "datetime",
      key: "datetime",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(text)}</span>
      ),
    },
    {
      title: "Convo Type",
      dataIndex: "convo_type",
      key: "convo_type",
      render: (_, { typeofconversation }) => (
        <>
          {typeofconversation != null && (
            <>
              {typeofconversation.split(",").map((tag) => {
                let color;
                if (tag.trim() === "email_chat") {
                  color = "purple";
                } else if (tag.trim() === "email") {
                  color = "blue";
                } else {
                  color = "red";
                }
                return (
                  <Tag color={color} key={tag.trim()}>
                    Email
                  </Tag>
                );
              })}
            </>
          )}
        </>
      ),
      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Candidate Name",
      dataIndex: "name",
      key: "name",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "Job Description",
      dataIndex: "job_description",
      key: "job_description",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "Skill Evaluation",
      dataIndex: "selection_status",
      key: "selection_status",
      render: (handed_off, record) => {
        let iconColor, iconName, textColor;
        switch (handed_off) {
          case "Rejected":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          case "Selected":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;

          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }
        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<PopConfirmJDQa content={record.qa_content} />
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {handed_off && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>{" "}
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(handed_off)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },

    {
      title: "Resume",
      dataIndex: "url",
      key: "url",
      align: "center",
      render: (text) => (
        <Tooltip title={text}>
          <a href={text} target="_blank" rel="noopener noreferrer">
            <span
              style={{
                display: "inline-block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {/* {text ? (`${text.slice(0, 15)}...`):("")} */}
              <Icon
                icon="vscode-icons:file-type-pdf2"
                style={{ fontSize: "20px" }}
              />{" "}
            </span>
          </a>
        </Tooltip>
      ),
    },
    {
      title: " ",
      key: "action",
      render: (text, record, index) => (
        <Space size="middle">
          <a
            className="view-more"
            onClick={() => handlehistory(record)}
            style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
          >
            View more
          </a>
        </Space>
      ),
      onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  ];
  const handlehistory = (record) => {
    setRecords(record);
    setHistoryOpen(true);
  };
  const handleCancel = () => {
    setHistoryOpen(false);
  };
  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="dashboard-board-background-table mobile-background"
        >
          <Table
            dataSource={filteredRecords}
            columns={columns}
            scroll={{ x: 1500 }}
            className="record-dashboard-table"
          />
        </Col>
      </Row>
      <Modal
        open={historyopen}
        footer={false}
        onCancel={handleCancel}
        className="report-table-modal"
        width={1000}
      >
        <Historymodal records={records} />
      </Modal>
    </>
  );
};
export default JDrecords;
