import React from "react";
import { Col, Row } from "antd";
import NavBar from "../../Header/NavBar";
import Sidemenu from "../../Sidemenu/Sidemenu";
import Tokenmain from "./Tokenmain";
const TokenBodyDesktop = () => {
    return (
        <>

            <NavBar />

            <Row>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={2}
                    xl={2}
                    className="body-margin-top body-margin-bottom-mobile"
                >
                    <Sidemenu />
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={22}
                    xl={22}>
                    <Tokenmain />

                </Col>

            </Row>



        </>
    )
}
export default TokenBodyDesktop;