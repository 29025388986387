import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import RecordDesktopBoard from "./Desktop/RecordDesktopBoard";

const RecordsBody = () => {
   
    return (
        <> 
            <div className="conversation-desktop">
                <RecordDesktopBoard />
            </div>
            <div className="conversation-mobile">

            </div>
        </>
    )
}
export default RecordsBody;