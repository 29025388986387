import { Row, Col, Tag, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { capitalizeWords } from "../../customhook/CustomHoom";
import MarkdownToJSX from "markdown-to-jsx";
import PopConfirmJDQa from "./PopConfirmJDQa";
const JDdetails = (props) => {
  const data = props.records;
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Candidate Name:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.name)}
            </Col>
          </Row>

          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Service:
            </Col>
            <Col span={12} className="white-font question-font">
              {data.service}
            </Col>
          </Row>

          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Resume:
            </Col>
            <Col span={12} className="white-font question-font">
              <a href={data.url} target="_blank" rel="noopener noreferrer">
                {/* {data.url ? (`${data.url.slice(0, 15)}...`):("")} */}
                <Icon
                  icon="vscode-icons:file-type-pdf2"
                  style={{ fontSize: "20px" }}
                />
              </a>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Type:
            </Col>
            <Col span={12} className="white-font question-font">
              <Tag
                color={
                  data.typeofconversation === "email_chat"
                    ? "blue"
                    : data.typeofconversation === "voice"
                    ? "purple"
                    : "default"
                }
              >
                {capitalizeWords("Email")}
              </Tag>
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Service category:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.category)}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              JD :
            </Col>
            <Col span={12} className="white-font question-font">
              <a
                href={data.voice_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* {data.url ? (`${data.url.slice(0, 15)}...`):("")} */}
                {data.voice_url ? (
                  <Icon
                    icon="vscode-icons:file-type-pdf2"
                    style={{ fontSize: "20px" }}
                  />
                ) : (
                  ""
                )}
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default JDdetails;
