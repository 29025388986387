import React, { useRef, useState, useEffect } from "react";
import { Slider, Row, Col } from "antd";
import "../CSS/imageselection.css";
const Custommask = (props) => {
  const canvasRef = useRef(null);
  const imgTag = props.imgtag;
  const [context, setContext] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [brushSize, setBrushSize] = useState(10);
  const [brushColor, setBrushColor] = useState("#ff0000a5");
  

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    setContext(ctx);
  }, []);

  useEffect(() => {
    if (context) {
      const img = new Image();
      img.src = imgTag;
      img.onload = () => {
        canvasRef.current.width = img.width;
        canvasRef.current.height = img.height;
        context.drawImage(img, 0, 0);
      };
    }
  }, [context]);

  const startDrawing = () => {
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    setIsDrawing(false);
    const maskedImageDataURL = canvasRef.current.toDataURL();
    props.setmaskprompt(maskedImageDataURL);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    context.beginPath();
    context.arc(x, y, brushSize / 2, 0, Math.PI * 2);
    context.fillStyle = brushColor;
    context.fill();
    context.closePath();
  };

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <canvas
              ref={canvasRef}
              onMouseDown={startDrawing}
              onMouseUp={finishDrawing}
              onMouseMove={draw}
              onMouseLeave={finishDrawing}
              style={{ border: "1px solid black" }}
            />
          </Col>
          <Col span={12}>
            {" "}
            <div>
              <label className="mask-image-brush">Brush Size:</label>
              <Slider
                min={1}
                max={40}
                value={brushSize}
                onChange={(value) => setBrushSize(value)}
                step={1}
              />
            </div>
          </Col>
          {/* <Col span={12}>
            {" "}
            <div>
              <label className="mask-image-brush">Brush Color:</label>
              <br />
              <input
                type="color"
                value={brushColor}
                onChange={(e) => setBrushColor(e.target.value)}
              />
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Custommask;
