import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import MobilePodList from "./MobilePodList";
import Mobilebottminfo from "./Mobilebottominfo"
import Mobilebottominfo from "./Mobilebottominfo";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import MobileChatpart from "./MobileChatpart";
import { useNavigate } from "react-router-dom";
import {
    setChatSessionID,
    setTempChatSessionID,
    removeHistoryChatMessage,
    setRemaingCredits,
} from "../../Slice/reducer/chatReducer";
import { useParams } from "react-router-dom";

const MobileConversationBody = () => {
    const { id } = useParams();
    const [draweropen, setdrawerOpen] = useState(false);
    const [infoname, setInfoname] = useState("");
    const dispatch = useDispatch();
    const navigateFn = useNavigate();
    const [drawerbottomopen, setdrawerbottomOpen] = useState(false);
    const { remainingcredits } = useSelector((state) => state.chat);
    const onClose = () => {
        setdrawerOpen(false);
    };
    const onbottomClose = () => {
        setdrawerbottomOpen(false)
    }
    useEffect(() => {
        if (id) {
            setdrawerOpen(false);
        }
    }, [id])

    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <Row className="chat-header-part">
                <Col className="chat-header-logo-title"> Agent Hub </Col>
                <Col>
                    <img
                        src={navLogo}
                        alt="nav-logo "
                        className="navlogo cursor-pointer"
                    />
                </Col>
            </Row>
            <Row className="chat-header-sub-part">
                <Col className="chat-icon-button">
                    <Button size="small" className="chat-cancel-icon" onClick={() => setdrawerOpen(!draweropen)}>{draweropen ? <Icon icon="octicon:x-16" className="chat-icon-inner-button" /> : <Icon icon="ph:dots-three-bold" className="chat-icon-inner-button" />}</Button>
                </Col>
                <Col>
                    <div className="chat-agent-name">
                        CloudPricingPro
                    </div>
                    {remainingcredits ? (<div className="chat-agent-sub-name">
                        AIVolvex Credits : {remainingcredits}
                    </div>) : (<><div className="chat-agent-sub-name "><br /></div></>)}
                </Col>
                <Col className="chat-icon-button">
                    <Button size="small" className="chat-add-icon"
                        onClick={() => {
                            dispatch(setChatSessionID(""));
                            dispatch(setTempChatSessionID(""));
                            dispatch(removeHistoryChatMessage());
                            navigateFn("/conversation");

                        }} ><Icon icon="octicon:plus-16" className="chat-icon-inner-button" /></Button>
                </Col>
            </Row>

            <div style={{ flex: 1, overflowY: 'auto' }} className="chart-part-mobile">
                <Row>

                    <Col span={24}>
                        {/* <Row className="chat-bottom-input-body">
                     
                    </Row>
                    <Row gutter={[8, 8]} className="chat-bottom-input-footer">
                        <Col md={18} sm={17} xs={17}><div className="chat-input-type"><Input /><div>  </div></div></Col>
                        <Col span={4}> <Button size="small" className="chat-cancel-icon chat-bottom-button">10{" "} <img
                            src={aivolvex}
                            alt="Image"
                            className="token-footer-logo aivolvex-font"
                        /></Button></Col>
                        <Col md={2} sm={3} xs={3}>
                            <Dropdown
                                className="chat-bottom-drop"
                                menu={{
                                    items,
                                }}
                                placement="topRight"
                            >
                                <Button size="small" className="chat-add-icon chat-bottom-add chat-bottom-button "><Icon icon="octicon:plus-16" className="chat-icon-inner-button" /></Button>
                            </Dropdown>
                        </Col>
                    </Row> */}
                        <MobileChatpart setdrawerbottomOpen={setdrawerbottomOpen} setInfoname={setInfoname} />
                    </Col>

                </Row>
            </div>
            <Row className="chat-bottom-section">
                <Col span={24} ><SidemenuMobile /></Col>
            </Row>
            <div className="chat-mobile-pod-drawer">
                <Drawer className="chat-mobile-pod-drawer" placement="left" onClose={onClose} open={draweropen}>
                    <MobilePodList />
                </Drawer>
            </div>

            <div className="chat-mobile-bottom-draw">
                <Drawer lassName="chat-mobile-bottom-draw" placement="bottom" onClose={onbottomClose} open={drawerbottomopen}>
                    <Mobilebottominfo infoname={infoname} setdrawerbottomOpen={setdrawerbottomOpen} />
                </Drawer>
            </div>


        </div>
    </>)
}
export default MobileConversationBody;