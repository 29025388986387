import { memo } from "react";
import DashboardDesktop from './Desktop/DashboardDesktop';
import DashboardMobile from "./Mobile/DashboardMobile"
const DashboardFile = () => {
    return (
        <>
            <div className="conversation-desktop">
                <DashboardDesktop />
            </div>
            <div className="conversation-mobile">
                <DashboardMobile />
            </div>
        </>
    )
}
export default memo(DashboardFile);