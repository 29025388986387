import { memo } from "react";
import TagDetails from "./TagDetails";
import { Row } from "antd";

const TagAvatarConfig = (props) => {
  return (
    <>
      <div className="mobile-tag">


        <Row gutter={[24, 24]}>
          {props.records.map((data) => (
            <TagDetails
              records={data}
              type="avatar"
              avatarlist={props.avatarlist}
              setDataChangeStatus={props.setDataChangeStatus}
              datachangestatus={props.datachangestatus}
            />
          ))}
        </Row>
      </div>
    </>
  );
};

export default memo(TagAvatarConfig);
