import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { loginEmailcheck, welcomeEmail } from "../api/accountAction";
// import { userInfoMethod } from "../slice/userSlice";
import { message, Spin } from "antd";
import preloader from "../../assets/loader/pre_loader.gif";

const AzureAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const [messagestatus, setmessageStatus] = useState(false);
  const [errormessagestatus, setErrorMessageStatus] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    const urlParams = new URLSearchParams(location.search);
    const hashParams = location.hash.substring(1);
    const result = hashParams.split("&");
    const idToken = result
      .find((param) => param.startsWith("id_token="))
      .split("=")[1];
    // console.log(idToken);
    const decodedToken = jwtDecode(idToken);
    console.log(decodedToken);
    // console.log(decodedToken);
    localStorage.setItem("_token", idToken);
    // Access the payload data from the decoded token
    const user_id = decodedToken["cognito:username"];
    const exp_time = decodedToken["exp"];
    const email = decodedToken.email;
    const input = {
      user_id: email,
    };

    dispatch(loginEmailcheck(input))
      .unwrap()
      .then(({ data }) => {
        setErrorMessageStatus(false);
        const encodedString = btoa(
          JSON.parse(data.aivolvex_control_plane)[0].company_name
        );
        const encodedEmail = btoa(email);
        const normalencodedString = btoa("azure");

        localStorage.setItem(
          "AIVolveXIdentityServiceProvider.com",
          encodedString
        );
        localStorage.setItem(
          "AIVolveXIdentityServiceProvider.logtype",
          normalencodedString
        );
        localStorage.setItem(
          "AIVolveXIdentityServiceProvider.auth",
          encodedEmail
        );
        // dispatch(welcomeEmail({ email }))
        //   .unwrap()
        //   .then((data) => {
        //     window.location.href = "/conversation";
        //   })
        //   .catch((err) => {

        //     console.log(err.message);
        //     message.error(err.message);
        //   });

        window.location.href = "/conversation";
      })
      .catch((err) => {
        setErrorMessageStatus(true);
        console.log(err.message);
      });
  }, [location]);

  // const ssoAccountLogout = async () => {
  //   await msalInstance.logoutPopup();
  // };

  return (
    <>
      {loader ? (
        <>
          <div className="aivolvex-loader">
            <img src={preloader} width="30%" />
          </div>
        </>
      ) : (
        <>
          {errormessagestatus && (
            <div className="azure-error-message">
              <div className="azure-error-message-centent">
                Oops! Something went wrong. Please contact our support team for
                assistance.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AzureAuth;
