import { listAvatar,mailboxsend } from "../../api/accountAction";

export const getAvatarList = (input, preloader, setPreloader) => (dispatch) => {
  dispatch(listAvatar(input))
    .unwrap()
    .then(({ data }) => {
      setPreloader(true);
      // console.log(data);
    })
    .catch((err) => {
      setPreloader(false);

      console.log(err);
      // message.error(err);
    });
};
export const sendemail = async (dispatch, input) => {
  try {
    const response = await dispatch(mailboxsend(input)).unwrap();
  } catch (error) {
    console.error(error);
  }
};
