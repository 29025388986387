import React, { useState } from "react";
import { Input, Tag, Row, Col, Button, Form } from "antd";
import "../CSS/conversation.css";
import MarkdownToJSX from "markdown-to-jsx";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { sendemail } from "../Auth/ConversationAuth";
import { useDispatch } from "react-redux";
const EmailInput = (props) => {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [email, setEmail] = useState("Hi [Your email recipient]");
  const [emailedit, setEmailedit] = useState(true);
  const [name, setName] = useState("Thanks and Regards, [Your Name]");
  const [nameedit, setNameedit] = useState(true);
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && emails.indexOf(inputValue) === -1) {
      setEmails([...emails, inputValue]);
    }
    setInputValue("");
  };

  const handlesubmit = () => {
    const emailMarkdownText = <ReactMarkdown>{email}</ReactMarkdown>;
    const nameMarkdownText = <ReactMarkdown>{name}</ReactMarkdown>;
    const emailMarkdown = emailMarkdownText.props.children;
    const nameMarkdown = nameMarkdownText.props.children;
    const mergedContent = `${emailMarkdown.trim()}\n\n${props.recorddata.trim()}\n\n${nameMarkdown.trim()}`;
    const input = {
      to: emails.join(","),
      subject: subjectValue,
      body: mergedContent,
    };
    sendemail(dispatch, input);
    setSubjectValue("");
    setName("Thanks and Regards, [Your Name]");
    setEmail("Hi [Your email recipient]");
    setInputValue("");
    setEmails([]);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlenameChange = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    if (!props.mailbox) {
      setSubjectValue("");
      setName("Thanks and Regards, [Your Name]");
      setEmail("Hi [Your email recipient]");
      setInputValue("");
      setEmails([]);
    }
  }, [props.mailbox]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <br></br>
        <Col span={24} className="d-flex justify-content-start mt-2">
          <Button onClick={() => handlesubmit()} className="outlooksend">
            <strong>Send</strong>
          </Button>
        </Col>
        <Col span={4} className="outlooksend">
          To
        </Col>{" "}
        <Col span={20}>
          <div className="outlook-modal">
            <Input
              type="email"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              placeholder="Enter email"
            />
            <div style={{ marginTop: 8 }}>
              {emails.map((email) => (
                <Tag
                  key={email}
                  closable
                  onClose={() => {
                    const newEmails = emails.filter((e) => e !== email);
                    setEmails(newEmails);
                  }}
                >
                  {email}
                </Tag>
              ))}
            </div>
          </div>
        </Col>{" "}
        <Col span={4} className="outlooksend">
          Subject
        </Col>
        <Col span={20}>
          {" "}
          <div className="outlook-modal">
            <Input
              placeholder="Add a subject"
              onChange={(e) => setSubjectValue(e.target.value)}
            />
          </div>
        </Col>
        <br />
        <Col span={24}>
          {emailedit ? (
            <span onClick={() => setEmailedit(!emailedit)}>{email}</span>
          ) : (
            <Input
              placeholder="Your email recipient"
              value={email}
              onChange={handleEmailChange}
              onPressEnter={() => setEmailedit(!emailedit)}
            />
          )}
        </Col>
        <Col span={24}>
          {" "}
          <MarkdownToJSX>{props.recorddata}</MarkdownToJSX>
        </Col>
        <Col span={24}>
          {" "}
          {nameedit ? (
            <span onClick={() => setNameedit(!nameedit)}>{name}</span>
          ) : (
            <Input
              placeholder="Your email recipient"
              value={name}
              onChange={handlenameChange}
              onPressEnter={() => setNameedit(!nameedit)}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmailInput;
