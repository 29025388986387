import { Col, Row, Tabs } from "antd";
import Handoff from "./Handoff";
import Details from "./Details";
import EmailContent from "./EmailContent";
import { useDispatch, useSelector } from "react-redux";
import JDdetails from "./JDdetails";
import JDTranscript from "./JDTranscript";
import { useParams } from "react-router-dom";

const Historymodal = (props) => {
  const { avatardetails } = useSelector((state) => state.chat);
  const { id } = useParams();
  const items = [
    {
      key: "1",
      label: avatardetails.chat_type === "voice" ? "Transcript" : "History",
      children: <Handoff record={props.records} />,
    },
    {
      key: "2",
      label: "Details",
      children: (
        <Details records={props.records} avatardetails={avatardetails} />
      ),
    },
    {
      key: "3",
      label: "Next Best Action",
      children: <EmailContent records={props.records} />,
    },
  ];
  const defaultitem = [
    {
      key: "1",
      label: "Report",
      children: (
        <>
          <div className="public-agent-desktop">
            <JDTranscript record={props.records} />
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Details",
      children: (
        <JDdetails records={props.records} avatardetails={avatardetails} />
      ),
    },
    {
      key: "3",
      label: "Next Best Action",
      children: <EmailContent records={props.records} />,
    },
  ];
  const defaultitemfor = [
    {
      key: "1",
      label: avatardetails.chat_type === "voice" ? "Transcript" : "History",
      children: <Handoff record={props.records} />,
    },
    {
      key: "2",
      label: "Details",
      children: (
        <Details records={props.records} avatardetails={avatardetails} />
      ),
    },
  ];

  return (
    <>
      <Row className="record-history-tab chatbot-dashboard-main-body">
        <Col span={24}>
          <Tabs
            className="record-tab"
            defaultActiveKey="1"
            items={id == 724 ? defaultitem : id == 731 ? defaultitemfor : items}
          />
        </Col>
      </Row>
    </>
  );
};
export default Historymodal;
