import React, { useEffect, useState } from "react";
import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Input, Card, Button, Avatar, Select, Modal, message, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import '../CSS/Token.css';
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { getAvatarList, agentlog, agentupdate, agentinsert, agentlist, agentdelete, getAvatarDetails } from "../../api/accountAction";
import { useDispatch } from "react-redux";
import AgentInputFunction from "./AgentInput";
import { IoTrashBin } from "react-icons/io5";
import { simplifyNumber } from "../../customhook/CustomHoom";
const TokenAgent = (props) => {
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );
    const userName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );
    const dispatch = useDispatch();
    const [logdata, setLogdata] = useState([])
    const [isEditable, setIsEditable] = useState(false);
    const [avatarlist, setAvatarList] = useState([]);
    const [agents, setAgents] = useState([]);
    const [submitagent, setSubmitagent] = useState(false);
    const [savestatus, setSavestatus] = useState(false)
    const [agentname, setAgentname] = useState('Costdemaze');
    const [value, setValue] = useState(0);
    const [cardrecord, setCardrecord] = useState([])
    const [selectedAgent, setSelectedAgent] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [data, setData] = useState([]);
    const [selectedAvatarId, setSelectedAvatarId] = useState(null);
    const [loghistory, setLoghistory] = useState(false);
    const [totalcredit, settotalcredit] = useState()
    const [remaincredit, setremaincredit] = useState();
    const [edittoken, setEdittoken] = useState(false)
    const [updatestatus, setUpdatestatus] = useState(false)
    const [updateinput, setupdateinput] = useState([])
    const [insertstatus, setInsertstatus] = useState(false)
    const [addcard, setaddcard] = useState(false)
    const [avatardetails, setAvatarDetails] = useState([]);
    const [newtotalcredit, setnewtotalcredit] = useState(props.viewdetails.month_details.total_tokens)
    // const difference = props.viewdetails.month_details.total_tokens - totalAvailableTokens;
    // console.log(difference)
    const [newavailablecredit, setnewavailablecredit] = useState(0)


    const handleAddData = () => {
        if (!newavailablecredit || newavailablecredit < 0) {
            message.error("Not enough tokens available");
        } else {
            setaddcard(true)
        }

        const newData = {
            avatar_name: selectedAgent,
            year: props.viewdetails.year,
            month_index: props.viewdetails.month_index,
            remaining_token: inputValue,
        };
        const newAgent = {
            name: '',
            creditsRemaining: 0,
        };
        setAgents([...agents, newAgent]);
        setData([...data, newData]);
    };
    const onChangeagent = (value) => {
        // setAgentname(value)
        // setSubmitagent(true)
        const selectedAvatar = avatarlist.find(avatar => avatar.avatar_name === value);
        if (selectedAvatar) {
            setSelectedAvatarId(selectedAvatar.id);
        } else {
            setSelectedAvatarId(null);
        }

    }
    const handleSaveClick = () => {
        setaddcard(false)
        // setaddcard(true)
        setSubmitagent(true)
        setIsEditable(false);
        props.setupdatestatus(true)
        const newData = {
            avatar_name: selectedAvatarId,
            year: props.viewdetails.year,
            month_index: props.viewdetails.month_index,
            remaining_token: value,
        };
        setData([...data, newData]);
        setSavestatus(true)
        const insertinput = {
            companyName: companyName,
            user_id: userName,
            avatar_id: selectedAvatarId,
            year: props.viewdetails.year,
            month: props.viewdetails.month_index,
            user_id: userName,
            avatar_token: value,
        };
        dispatch(agentinsert(insertinput))
            .unwrap()
            .then(({ data }) => {
                const inputData = JSON.parse(data.aivolvex_control_plane);
                // console.log(inputData)
                if (inputData.includes('Not enough tokens available')) {
                    message.error("Not enough tokens available");
                } else {
                    message.success("Credits are inserted")
                    setInsertstatus(!insertstatus);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handlelogadd = () => {
        setaddcard(false)
    }
    const selectOptions = avatarlist.map(item => ({
        value: item.avatar_name,
        label: item.avatar_name,
    }));
    const uniqueSelectOptions = selectOptions.filter(option => {
        return !cardrecord.some(recordItem => recordItem.avatar_name === option.value);
    });
    const handlelog = (index) => {
        const selectedAvatar = avatarlist.find(avatar => avatar.avatar_name === cardrecord[index].avatar_name);
        setLoghistory(true)
        const input = {
            companyName: companyName,
            year: props.viewdetails.year,
            month: props.viewdetails.month_index,
            user_id: userName,
            avatar_id: selectedAvatar.id,
        }
        dispatch(agentlog(input))
            .unwrap()
            .then(({ data }) => {
                const result = JSON.parse(data.aivolvex_control_plane);
                // console.log(result);
                setLogdata(result)
            })
            .catch((err) => {
                console.log(err);
            });

    }
    const handleCancel = () => {
        setLoghistory(false)
    }
    useEffect(() => {
        const inputdata = {
            companyName: companyName,
        }
        dispatch(getAvatarList(inputdata))
            .unwrap()
            .then(({ data }) => {
                setAvatarList(JSON.parse(data.aivolvex_control_plane));
            })
            .catch((err) => {
                console.log(err);
            });
        const input = {
            companyName: companyName,
            user_id: userName,
            year: props.viewdetails.year,
            month_index: props.viewdetails.month_index,
        }

        // 
        dispatch(agentlist(input))
            .unwrap()
            .then(({ data }) => {
                const inputData = JSON.parse(data.aivolvex_control_plane);
                console.log(inputData)
                const sumofremain = inputData.reduce((acc, item) => acc + item.remaining_token, 0);
                const sumoftotal = inputData.reduce((acc, item) => acc + item.total_tokens, 0);
                setremaincredit(sumofremain)
                settotalcredit(sumoftotal)
                setCardrecord(inputData)
                console.log(newtotalcredit)
                console.log(sumofremain)
                setnewavailablecredit(newtotalcredit - sumofremain)
            })
            .catch((err) => {
                console.log(err);
            });



    }, [props, updatestatus, savestatus, insertstatus])


    // useEffect(() => {
    //     const avatarDetailsArray = [];

    //     cardrecord.forEach((card) => {
    //         const selectedAvatar = avatarlist.find((avatar) => avatar.avatar_name === card.avatar_name);
    //         if (selectedAvatar) {
    //             const input = {
    //                 user_id: userName,
    //                 id: selectedAvatar.id,
    //             };
    //             dispatch(getAvatarDetails(input))
    //                 .unwrap()
    //                 .then(({ data }) => {
    //                     const avatarDetails = JSON.parse(data.aivolvex_control_plane)[0];
    //                     console.log(avatarDetails)
    //                     avatarDetailsArray.push({
    //                         id: avatarDetails.id,
    //                         avatar_name: avatarDetails.avatar_name,
    //                         avatar_image: avatarDetails.avatar_image,
    //                     });
    //                     console.log(avatarDetailsArray);
    //                 })
    //                 .catch((err) => {
    //                     console.log(err);
    //                 });
    //         }
    //     });

    //     console.log(avatarDetailsArray);
    // }, []);

    const backtomain = () => {
        props.setNewview(!props.newview)
        props.setupdatestatus(!props.updatestatus)
    }
    const [edittokens, setEdittokens] = useState(Array(cardrecord.length).fill(false));
    const handleinputedit = (index) => {
        const updatedEdittokens = [...edittokens];
        updatedEdittokens[index] = true;
        setEdittokens(updatedEdittokens);

    }
    const handleSubmitClick = (index) => {
        setaddcard(false)

        const updatedEdittokens = [...edittokens];
        updatedEdittokens[index] = false;
        setEdittokens(updatedEdittokens);
        dispatch(agentupdate(updateinput))
            .unwrap()
            .then(({ data }) => {
                const input = JSON.parse(data.aivolvex_control_plane);
                if (input.includes('not eligible')) {
                    message.error("not updated");
                } else {
                    message.success("updated");
                    setUpdatestatus(!updatestatus)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handlegetvalue = (values, index) => {
        const previousValue = cardrecord[index].total_tokens;
        cardrecord[index].total_tokens = values;
        const selectedAvatar = avatarlist.find(avatar => avatar.avatar_name === cardrecord[index].avatar_name);
        if (selectedAvatar) {
            setSelectedAvatarId(selectedAvatar.id);
        } else {
            setSelectedAvatarId(null);
        }
        const insertinput = {
            companyName: companyName,
            user_id: userName,
            avatar_id: selectedAvatar.id,
            year: props.viewdetails.year,
            month: props.viewdetails.month_index,
            user_id: userName,
            avatar_token: values,
        };
        setupdateinput(insertinput)
        // const totalAvailableTokens = cardrecord.reduce((total, data) => total + data.remaining_token, 0);
        // console.log(totalAvailableTokens);
        const totalTotalTokens = cardrecord.reduce((total, data) => {
            const parsedTotalTokens = parseInt(data.total_tokens, 10) || 0;
            return total + parsedTotalTokens;
        }, 0);
        // console.log(totalAvailableTokens)
        // console.log(totalTotalTokens)

        const difference = values - previousValue;
        // setnewtotalcredit(newtotalcredit + difference)
        // console.log(newavailablecredit + difference)
        const token = newavailablecredit + difference
        // setnewavailablecredit(newavailablecredit - difference)
        // setnewtotalcredit(values)
        // setnewtotalcredit(totalAvailableTokens)
        let showMessage = true;
        if (totalTotalTokens > props.viewdetails.month_details.total_tokens) {
            message.error("Insufficient tokens allocated")
        }
        const updatedData = cardrecord.map((record, index) => {
            const newValue = values[index] || 0;
            // console.log(newValue)
            if (newValue > record.total_tokens) {
                record.total_tokens = newValue;
            } else if (showMessage) {
                showMessage = false;
                // console.log(`New value is not above the initial value for index ${index}`);
                // message.error("Insufficient tokens allocated")
            }
            return record;
        });

        // console.log(updatedData)
        const total_remaining_token = updatedData.reduce((total, data) => +total + +data.total_tokens, 0);
        setnewavailablecredit(newtotalcredit - total_remaining_token);
        // console.log(newtotalcredit - total_remaining_token);
        // console.log(newtotalcredit)
    }
    const handledelete = (index) => {
        const selectedAvatar = avatarlist.find(avatar => avatar.avatar_name === cardrecord[index].avatar_name);
        const input = {
            companyName: companyName,
            user_id: userName,
            year: props.viewdetails.year,
            month: props.viewdetails.month_index,
            avatar_id: selectedAvatar.id,
        }
        if (cardrecord[index].total_tokens == cardrecord[index].remaining_token) {
            message.error("deleted")
            // console.log(input)
            dispatch(agentdelete(input))
                .then(({ data }) => {
                    // const input = JSON.parse(data.aivolvex_control_plane);
                    // console.log(data)
                    setUpdatestatus(!updatestatus)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
    const cancel = (e) => {
        console.log(e);
    };
    return (
        <>
            <div className="pro-body">
                <div className="pro-sub-body">
                    <br />
                    <Row span={24} className="sub-header" gutter={[24, 24]}>
                        <Col xs={24}
                            sm={24}
                            md={24}
                            lg={13}
                            xl={13} className="agent-header-button agent-header-button-text-two back-icon-sub">
                            <div className="p-1" onClick={backtomain}><Icon icon="fluent-mdl2:navigate-back" className="back-icon cursor-pointer" /></div>
                            <div><h1 className="body-title" >Agent</h1></div>
                        </Col>
                        <Col xs={24}
                            sm={24}
                            md={24}
                            lg={3}
                            xl={3} >
                            <div className="agent-header-button-design credit-button" style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{props.viewdetails.month_details.monthname}-{props.viewdetails.month_details.year}</span>
                            </div>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={4}
                            xl={4} >

                            <div className="agent-header-button-design credit-button" style={{ display: 'flex', alignItems: 'center' }}>

                                <span>Actual Credits : {simplifyNumber(newavailablecredit)}</span><span></span>
                                <img
                                    src={aivolvex}
                                    alt="Image"
                                    className="token-nav-logo aivolvex-font credit-image "
                                />
                            </div>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={4}
                            xl={4} >
                            <div className="agent-header-button-design credit-button" style={{ display: 'flex', alignItems: 'center' }}>
                                <span>Allocated Credit: {simplifyNumber(newtotalcredit)} </span><span></span>
                                <img
                                    src={aivolvex}
                                    alt="Image"
                                    className="token-nav-logo aivolvex-font credit-image "
                                />
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={[24, 24]}>
                        {cardrecord.map((record, index) => (
                            <Col key={index} xs={24} sm={24} md={24} lg={5} xl={5}>
                                <div className="aivolvex-dashboard-outline-setup token-border token-agent">
                                    <Card className="token-card-main">
                                        <Row className="agent-button" gutter={[16, 16]}>
                                            <Col><Button className="agent-icon-button" onClick={() => handlelog(index)}>
                                                <Icon icon="subway:refresh-time" className="agent-icon" />
                                            </Button>
                                            </Col>
                                            <Col>
                                                {/* <Button className="agent-icon-button delete-icon-button" onClick={() => handledelete(index)}>
                                                    <IoTrashBin className="delete-icon" />
                                                </Button> */}
                                                <Popconfirm
                                                    title="Are you sure to remove the Agent?"
                                                    onConfirm={() =>
                                                        handledelete(index)
                                                    }
                                                    onCancel={cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    className="history-delete-modal"
                                                >
                                                    <Button
                                                        //  className="agent-icon-button delete-icon-button"
                                                        className="delete-icon-button"
                                                        style={{ width: "32px" }}
                                                    >
                                                        <IoTrashBin className="prompts-history-delete-icon" />
                                                    </Button>

                                                </Popconfirm>
                                            </Col>


                                        </Row>
                                        <Row className="token-card-title">
                                            <Avatar size={80} icon={<UserOutlined />} />
                                        </Row>
                                        <Row span={24}>
                                            <div className="agent-text">{record.avatar_name}</div>
                                        </Row>
                                        <Row>
                                            <Col span={18} className="token-button">
                                                {edittokens[index] ? (
                                                    <Button className="token-card token-first" style={{ display: 'flex', alignItems: 'center' }}>
                                                        {/* <Input
                                                            type="number"
                                                            value={record.total_tokens}
                                                            bordered={false}
                                                            onChange={(e) => handlegetvalue(e.target.value)}
                                                        /> */}
                                                        <AgentInputFunction index={index} handlegetvalue={handlegetvalue} initalvalue={record.total_tokens} />
                                                        <div style={{ flex: 1 }}></div>
                                                        <img
                                                            src={aivolvex}
                                                            alt="Image"
                                                            className="token-nav-logo aivolvex-font"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="token-card token-first" style={{ display: 'flex', alignItems: 'center' }}>

                                                        <span>{simplifyNumber(record.total_tokens)}</span>
                                                        <div style={{ flex: 1 }}></div>
                                                        <img
                                                            src={aivolvex}
                                                            alt="Image"
                                                            className="token-nav-logo aivolvex-font"
                                                        />
                                                    </Button>
                                                )}
                                                {/* <Button className="token-card token-first" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span>{record.total_tokens}</span>
                                                    {edittokens[index] ? (
                                                    <Button className="token-card token-success save-button-token" onClick={() => { handleSubmitClick(index) }}>
                                                        <span>{record.total_tokens}</span>
                                                    </Button>
                                                ) : (
                                                    <Button className="token-card token-success" onClick={() => { handleinputedit(index) }}>
                                                        <Icon icon="wpf:edit" />
                                                    </Button>
                                                )}
                                                    
                                                    <div style={{ flex: 1 }}></div>
                                                    <img src={aivolvex} alt="Image" className="token-nav-logo aivolvex-font" />
                                                </Button> */}
                                            </Col>
                                            <Col span={6} className="token-button">
                                                {edittokens[index] ? (
                                                    <Button className="token-card token-success save-button-token" onClick={() => { handleSubmitClick(index) }}>
                                                        <Icon icon="mdi:tick" />
                                                    </Button>
                                                ) : (
                                                    <Button className="token-card token-success" onClick={() => { handleinputedit(index) }}>
                                                        <Icon icon="wpf:edit" />
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="token-button">
                                                <div className="credit-text token-credits-text">
                                                    Actual Credits : {simplifyNumber(record.remaining_token)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </Col>
                        ))}
                        {addcard ? (<>

                            <Col
                                // key={index}
                                xs={24}
                                sm={24}
                                md={24}
                                lg={5}
                                xl={5}
                            >
                                <div className="aivolvex-dashboard-outline-setup token-border token-agent">
                                    <Card className="token-card-main" >
                                        <Row className="agent-button">
                                            <Button className="agent-icon-button" onClick={handlelogadd}>
                                                <Icon icon="material-symbols-light:cancel-outline" className="agent-icon" />
                                            </Button>
                                        </Row>
                                        <Row className="token-card-title">
                                            <Avatar size={80} icon={<UserOutlined />} />
                                        </Row>
                                        <Row span={24} >

                                            <Select
                                                showSearch
                                                placeholder="Select an agent"
                                                onChange={onChangeagent}
                                                className="agent-text-select"
                                                options={uniqueSelectOptions}
                                            />

                                        </Row>
                                        <Row>
                                            <Col span={24} className="token-button">
                                                {isEditable ? (
                                                    <Button className="token-card token-first" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span>{value}</span>
                                                        <div style={{ flex: 1 }}></div>
                                                        <img
                                                            src={aivolvex}
                                                            alt="Image"
                                                            className="token-nav-logo aivolvex-font"
                                                        />
                                                    </Button>
                                                ) : (

                                                    <Button className="token-card token-first" style={{ display: 'flex', alignItems: 'center' }}>

                                                        <Input
                                                            type="number"
                                                            value={value}
                                                            bordered={false}
                                                            onChange={(e) => {
                                                                setValue(e.target.value)

                                                                const updatedData = cardrecord.map((record, index) => {
                                                                    const newValue = e.target.value || 0;
                                                                    // console.log(newValue)
                                                                    if (newValue > record.total_tokens) {
                                                                        record.total_tokens = newValue;
                                                                    }
                                                                    return record;
                                                                });

                                                                // console.log(updatedData)
                                                                const total_remaining_token = updatedData.reduce((total, data) => +total + +data.total_tokens, 0);
                                                                var token_check = total_remaining_token;
                                                                if (total_remaining_token === 0) {
                                                                    token_check = newtotalcredit;
                                                                }
                                                                if (newtotalcredit - e.target.value >= 0) {
                                                                    setnewavailablecredit(newtotalcredit - e.target.value);
                                                                } else {
                                                                    message.error("Insufficient tokens allocated");
                                                                    setnewavailablecredit(newtotalcredit - e.target.value);
                                                                }

                                                            }}
                                                        />
                                                        <div style={{ flex: 1 }}></div>
                                                        <img
                                                            src={aivolvex}
                                                            alt="Image"
                                                            className="token-nav-logo aivolvex-font"
                                                        />
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col span={24} className="token-button">
                                                <Button className="token-card token-success save-button-token" onClick={handleSaveClick}>
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col span={24} className="token-button">
                                                <div className="credit-text token-credits-text" >
                                                    Actual Credits : 0    </div>
                                            </Col>
                                        </Row> */}
                                    </Card>
                                </div>
                            </Col>
                        </>) : (<>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={5}
                                xl={5}
                            >
                                <div className="aivolvex-dashboard-outline-setup token-border token-agent">
                                    <Card className="token-card-main center-wrapper-add" onClick={handleAddData} >
                                        <div className="agent-add"><Icon className="icon-create" icon="fa6-solid:plus" /></div>
                                        <div>Add Agent</div>
                                    </Card>
                                </div>
                            </Col>
                        </>)
                        }
                    </Row>
                </div>
            </div>
            <Modal title="Log" open={loghistory} onCancel={handleCancel} footer={false}>
                {logdata.map((log, index) => {
                    const date = new Date(log.date_time);
                    const formattedDate = date.toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });

                    return (
                        <div key={index}>
                            <Row gutter={[16, 24]} className="log-body">
                                <Row className=" text-body-log log-body">
                                    <Col>
                                        <Row span={24}>
                                            <Col xs={10}
                                                sm={10}
                                                md={12}
                                                lg={4}
                                                xl={10} className="text-right-text"> Date and Time:</Col>
                                            <Col xs={14}
                                                sm={14}
                                                md={12}
                                                lg={4}
                                                xl={14} className="text-left-text">{formattedDate}</Col>
                                        </Row>
                                        <Row span={24}>
                                            <Col xl={10} sm={4} xs={4} className="text-right-text"> User:</Col>
                                            <Col xl={14} sm={20} xs={20} className="text-left-text"> {log.user_id}</Col>
                                        </Row>
                                        <Row span={24}>
                                            <Col xl={10} sm={5} xs={8} className="text-right-text"> Command:</Col>
                                            <Col xl={14} sm={19} xs={16} className="text-left-text"> {log.token_value}</Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Row>
                            <br />
                        </div>
                    );
                })}
            </Modal>

        </>
    )
}
export default TokenAgent;