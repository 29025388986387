import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import AudioToText from "../Desktop/AudioToText";
import { formatCurrentTime, getCurrentDayAndTime, generateTransactionId, htmlRemove } from "../../customhook/CustomHoom";
import {
    setChatMessage,
    deleteChatMessage,
    setLibraryQuestion,
    setChatSessionID,
    setTempChatSessionID,
    setPinnedStatus,
} from "../../Slice/reducer/chatReducer";

import { useParams } from "react-router-dom";
import {
    startConversation,
    pinnedAvatar,
    getEmbeddingfile,
    getFilecheck,
} from "../../api/accountAction";
import MobileChartBody from "./MobileChartBody"

const MobileConversationBody = (props) => {
    const {
        avatardetails,
        libraryquestion,
        chat_session_id,
        temp_chat_session_id,
        pinnedstatus,
    } = useSelector((state) => state.chat);
    //   const { chatmessage } = useSelector((state) => state.chat);
    const dispatch = useDispatch();
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );
    const userName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );
    const { id } = useParams();
    const currentTime = formatCurrentTime();
    const [message, setMessage] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [messageprocessstatus, setMessageProcessStatus] = useState(true);
    const handleClick = (label) => {
        props.setdrawerbottomOpen(true)
        props.setInfoname(label)
        setIsDropdownOpen(!isDropdownOpen);
    };
    // function getCurrentDayAndTime() {
    //     const options = {
    //         weekday: "short",
    //         hour: "numeric",
    //         minute: "2-digit",
    //         hour12: true,
    //     };
    //     const formatter = new Intl.DateTimeFormat("ist", options);
    //     const currentDate = new Date();
    //     const formattedDate = formatter.format(currentDate);
    //     return formattedDate;
    // }
    // const htmlRemove = (event) => {
    //     const htmlContent = event;
    //     const tempElement = document.createElement("div");
    //     tempElement.innerHTML = htmlContent;
    //     return tempElement.textContent;
    // };

    const getStartConversation = () => {
        if (messageprocessstatus && message.trim() !== "") {
            var chatsessionid;
            var temp_chatsesssion_id = temp_chat_session_id;
            setMessageProcessStatus(false);
            if (chat_session_id !== "") {
                chatsessionid = chat_session_id;
            } else {
                chatsessionid = generateTransactionId();

                dispatch(setChatSessionID(chatsessionid));
            }

            var temp_chatsesssion_id;
            if (temp_chat_session_id !== "") {
                temp_chatsesssion_id = temp_chat_session_id;
            } else {
                temp_chatsesssion_id = generateTransactionId();
                dispatch(setTempChatSessionID(temp_chatsesssion_id));
            }

            const chatinput = htmlRemove(message);
            var chatmessage = chatinput;
            setMessage("");
            dispatch(setLibraryQuestion(""));

            const avatar_chat_details_user = {
                text: chatinput,
                user: true,
                date: getCurrentDayAndTime(),
                type: "message",
            };
            dispatch(setChatMessage(avatar_chat_details_user));

            const avatar_chat_details_loader = {
                text: "",
                user: false,
                date: getCurrentDayAndTime(),
                type: "loader",
            };
            dispatch(setChatMessage(avatar_chat_details_loader));

            const input = {
                user_session: chatsessionid,
                avatar_id: id,
                chat: chatmessage,
                schema: companyName,
                user_name: userName,
                temp_session_id: temp_chatsesssion_id,
                avatar_name: avatardetails.avatar_name,
                pod_name: avatardetails.pod,
            };
            dispatch(startConversation(input))
                .unwrap()
                .then(({ data }) => {
                    dispatch(deleteChatMessage());
                    const result = JSON.parse(data.aivolvex_control_plane);
                    const responce = result.answer;
                    const avatar_chat_details = {
                        text: responce,
                        user: false,
                        date: getCurrentDayAndTime(),
                        type: "message",
                        message_list: result.document,
                        source: true,
                        image: avatardetails.avatar_image,
                        avatar_name: avatardetails.avatar_name,
                    };
                    dispatch(setChatMessage(avatar_chat_details));
                    setMessageProcessStatus(true);
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(deleteChatMessage());
                    const avatar_chat_details = {
                        text: "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?",
                        user: false,
                        date: getCurrentDayAndTime(),
                        type: "message",
                        image: avatardetails.avatar_image,
                    };
                    dispatch(setChatMessage(avatar_chat_details));
                    setMessageProcessStatus(true);
                });
        }
    };
    // function generateTransactionId() {
    //     const timestamp = Date.now().toString();
    //     const randomNum = Math.floor(Math.random() * 10000)
    //         .toString()
    //         .padStart(4, "0");
    //     return `${timestamp}-${randomNum}`;
    // }
    const handleKeyPress = (event) => {
        if (message.length > 0) {
            if (event.key === "Enter") {
                event.preventDefault();
                getStartConversation();
            }
        }
    };
    useEffect(() => {
        setMessage(libraryquestion);
    }, [libraryquestion]);
    const items = [
        {
            key: '1',
            label: (
                <>
                    <div onClick={() => handleClick('info')}><Icon icon="ph:info" style={{ fontSize: '24px' }} /></div>
                    <div>
                        Info
                    </div>
                </>
            ),
        },
        {
            key: '2',
            label: (
                <>
                    <div onClick={() => handleClick('library')}><Icon icon="bi:bookmark" style={{ fontSize: '24px' }} /></div>
                    <div>
                        Library
                    </div>
                </>
            ),
        },
        {
            key: '3',
            label: (
                <>
                    <div onClick={() => handleClick('trending')}><Icon icon="streamline:interface-content-fire-lit-flame-torch-trending" style={{ fontSize: '24px' }} /></div>
                    <div>
                        Trending
                    </div>
                </>
            ),
        },
        {
            key: '4',
            label: (
                <>
                    <div onClick={() => handleClick('history')}><Icon icon="solar:history-bold" style={{ fontSize: '24px' }} /></div>
                    <div>
                        History
                    </div>
                </>
            ),
        },
        {
            key: '5',
            label: (
                <>
                    <div onClick={() => handleClick('share')}><Icon icon="material-symbols:share-outline" style={{ fontSize: '24px' }} /></div>
                    <div>
                        Share
                    </div>
                </>
            ),
        },
    ];


    const handleDropdownVisibleChange = (visible) => {
        setIsDropdownOpen(visible);
    };
    return (
        <>
            {/* <Row className="chat-bottom-input-body" gutter={[16, 16]}>
                {id && (
                    <Col span={24} className="chat-mobile-body-header">
                        <Col>
                            <Row className="chat-header-title-image">
                                <img
                                    src={avatardetails.avatar_image}
                                    alt="avatar-img"
                                    className="volvex-chat-header-avatar-logo "
                                />
                            </Row>
                        </Col>
                        <Col>
                            <Row><span className="chat-header-logo-title">{avatardetails.avatar_name}</span></Row>
                            <Row><span className="chat-hearder-time">{currentTime}</span></Row>
                        </Col>
                    </Col>
                )}
            </Row> */}
            <div style={{ display: 'flex', flexDirection: 'column' }} className="chart-part-mobile">
                <div style={{ flex: 1, overflow: 'auto' }}>
                    <Row className="chat-mobilepart-main">
                        <Col span={24}>
                            <MobileChartBody />
                        </Col>
                    </Row>
                </div>
                <div>
                    {id && (<>
                        <Row gutter={[8, 8]} className="chat-bottom-input-footer d-flex align-items-end">
                            <Col md={20} sm={17} xs={17}>
                                <div className="chat-input-type">
                                    <Input.TextArea
                                        value={message}
                                        onChange={(e) => {
                                            setMessage(e.target.value);

                                        }}
                                        autoSize={{ minRows: 1 }}
                                        // autoSize={{ minRows: 1, maxRows: 4, resize: "none" }}
                                        className="chat-input-group"
                                        // className="input-container-textarea aivolvex-font"
                                        onKeyPress={handleKeyPress}
                                    />
                                    <AudioToText setMessage={setMessage} />
                                    <button
                                        onClick={() => getStartConversation()}
                                        // className="volvex-submit-btn"
                                        className="chat-input-group"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 41 41"
                                            fill="none"
                                            className="volvex-submit-btn-icon"
                                        >
                                            <path
                                                d="M3.84375 35.875L37.1562 20.5L3.84375 5.125V17.0833L27.707 20.5L3.84375 23.9167V35.875Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </button>

                                    <div>  </div>
                                </div>
                            </Col>
                            <Col md={2} sm={4} xs={4}>
                                <Button size="small" className="chat-cancel-icon chat-bottom-button">
                                    10{" "}
                                    <img
                                        src={aivolvex}
                                        alt="Image"
                                        className="token-footer-logo aivolvex-font"
                                    />
                                </Button>
                            </Col>
                            <Col md={2} sm={3} xs={3}>
                                {id ? (<>
                                    <Dropdown
                                        className="chat-bottom-drop"
                                        menu={{
                                            items,
                                        }}
                                        placement="topRight"
                                        onVisibleChange={handleDropdownVisibleChange}
                                        trigger={['click']}>
                                        {isDropdownOpen ? (
                                            <Button size="small" className="chat-add-icon-mobile chat-bottom-add chat-bottom-button ">
                                                <Icon icon="ph:x" className="chat-icon-inner-button" />
                                            </Button>
                                        ) : (
                                            <Button size="small" className="chat-cancel-icon chat-bottom-add chat-bottom-button ">
                                                <Icon icon="octicon:plus-16" className="chat-icon-inner-button" />
                                            </Button>
                                        )}

                                    </Dropdown>
                                </>) : (<>
                                    <Button size="small" className="chat-cancel-icon chat-bottom-add chat-bottom-button ">
                                        <Icon icon="octicon:plus-16" className="chat-icon-inner-button" />
                                    </Button>
                                </>)
                                }
                            </Col>

                        </Row>
                    </>)}

                </div>
            </div>

        </>
    )
}
export default MobileConversationBody;