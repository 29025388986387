import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Recentprompttable from "../Desktop/Recentprompttable"
import Recentpromptmobiletable  from "../Mobile/Recentpromptmobiletable"
const RecentPromptsMobile = () => {
    return (
        <>
            <Row className="chat-header-part">
                <Col className="chat-header-logo-title"> Recent Prompts</Col>
                <Col>
                    <img
                        src={navLogo}
                        alt="nav-logo "
                        className="navlogo cursor-pointer"
                    />
                </Col>
            </Row>
            <Row className="recent-mobile-body">
                <Col span={24}>
                    <Recentpromptmobiletable />
                </Col>
            </Row>
            <Row className="chat-bottom-section">
                <Col span={24} ><SidemenuMobile /></Col>
            </Row>
        </>
    )
}
export default RecentPromptsMobile;