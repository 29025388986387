import { Col, Row } from "antd";
import NavBar from "../../Header/NavBar";
import Sidemenu from "../../Sidemenu/Sidemenu";
import Privatepodlist from "./Privatepodlist";
import Recordmainbody from "./Recordmainbody";
import { useParams } from "react-router-dom";
const RecordDesktopBoard = () => {
    const { id } = useParams();

    return (
        <>
            <NavBar />
            <div className="body-bg">
                <Row className="desktop-conversaction-body">
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={2}
                        xl={2}
                        className="body-margin-top body-margin-bottom-mobile"
                    >
                        <Sidemenu />
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={5}
                        xl={5}
                        // className="volvex-avatar-list-body"
                        className="volvex-private-podlist"
                    >
                        <Privatepodlist />
                    </Col>

                    {id ? (
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={17}
                            xl={17}
                            className="dashboard-board-background"

                        >
                            <Recordmainbody />
                        </Col>
                    ) : (
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={17}
                            xl={17}
                            className="volvex-chat-body"
                        >

                        </Col>
                    )}



                </Row >
            </div >

        </>
    )
}
export default RecordDesktopBoard;