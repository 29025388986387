import React from "react";
import { Col, Row } from "antd";
import TokenBodyDesktop from "./Desktop/TokenBodyDesktop";
import TokenBodyMobile from "./Mobile/TokenBodyMobile";
const Tokenmenu = () => {
    return (
        <>


            <div className="conversation-desktop">
                <TokenBodyDesktop />
            </div>
            <div className="conversation-mobile">
                <TokenBodyMobile />
            </div>


        </>
    )
}
export default Tokenmenu;