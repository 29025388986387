import { Icon } from "@iconify/react";
import React, { useState, useEffect, useRef, memo } from "react";
import { franc } from "franc";

import startSound from "../../../assets/audio/conversaction/start.mp3";
import stopSound from "../../../assets/audio/conversaction/stop.mp3";

const AudioToText = (props) => {
  const [isListening, setIsListening] = useState(false);
  const [transcription, setTranscription] = useState("");
  const recognitionRef = useRef(new window.webkitSpeechRecognition());

  // Create audio elements
  const startAudio = new Audio(startSound);
  const stopAudio = new Audio(stopSound);

  useEffect(() => {
    const recognition = recognitionRef.current;

    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onstart = () => {
      //   console.log("Speech recognition started");
      const detectedLang = detectLanguage(startSound);
      // console.log(detectedLang);
      recognition.lang = detectedLang;

      setIsListening(true);
      // Play start sound
      startAudio.play();
    };

    recognition.onresult = (event) => {
      // console.log(event);

      let interimTranscription = "";
      let finalTranscription = "";
      // recognition.lang = "ta-IN";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;

        if (event.results[i].isFinal) {
          finalTranscription += transcript + " ";
        } else {
          interimTranscription += transcript;
        }
      }

      // Language detection logic for interim results
      const detectedLang = detectLanguage(interimTranscription);
      recognition.lang = detectedLang;

      props.setMessage(finalTranscription);
      setTranscription(finalTranscription);

      // If you want to capture interim results as well, you can use interimTranscription
    };

    recognition.onend = () => {
      //   console.log("Speech recognition ended");
      setIsListening(false);
      // Play stop sound
      stopAudio.play();
    };

    recognition.onerror = (event) => {
      // console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    return () => {
      recognition.stop();
    };
  }, []);

  const detectLanguage = (text) => {
    return franc(text);
  };

  const toggleListening = async (event) => {
    const recognition = recognitionRef.current;
    recognition.lang = "es-ES";
    // recognition.lang = "ta-IN";

    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
  };

  return (
    <>
      <label className="custom-file-upload d-flex justify-content-center align-items-center">
        <Icon
          icon="material-symbols:mic"
          className={`speech-to-text-container ${
            isListening ? "recording-icon" : "recording-icon-active"
          }`}
          onClick={toggleListening}
        />
      </label>
    </>
  );
};

export default memo(AudioToText);
