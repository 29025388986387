import { memo, useEffect, useState } from "react";
import TagHome from "./Desktop/TagHome";
import Taghomemobile from "./Mobile/Taghomemobile"
const Rolemainbody = () => {
    return (
        <>
            <div className="conversation-desktop">
                <TagHome />
            </div>
            <div className="conversation-mobile">
                <Taghomemobile />
            </div>

        </>
    )
}
export default Rolemainbody;