import {
  Col,
  Row,
  Input,
  Button,
  Upload,
  Modal,
  Form,
  Select,
  Switch,
} from "antd";
import { memo, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ChatBody from "./ChatBody";
import AvatarAboutDrawer from "./AvatarAboutDrawer";
import AvatarShare from "./AvatarShare";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateLastWord } from "../../Slice/slice";
import {
  startConversation,
  pinnedAvatar,
  getEmbeddingfile,
  getFilecheck,
  multifileuploads3,
} from "../../api/accountAction";
import {
  setChatMessage,
  deleteChatMessage,
  setLibraryQuestion,
  setChatSessionID,
  setTempChatSessionID,
  setPinnedStatus,
} from "../../Slice/reducer/chatReducer";
import AWS from "aws-sdk";
import AudioToText from "./AudioToText";
import {
  getCurrentDayAndTime,
  generateTransactionId,
  htmlRemove,
  generateRandomFourDigitNumber,
  formatCurrentTime,
} from "../../customhook/CustomHoom";
import TextArea from "antd/es/input/TextArea";
import ImageSelections from "./ImageSelections";
import Custommask from "./Custommask";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});

const ChatPart = () => {
  const { id } = useParams();
  const s3 = new AWS.S3();
  const [drawerstatus, setDrawarStatus] = useState(false);
  const [aibuttonstatue, setAiButtonStatus] = useState(true);
  // const { avatardetails, chatmessage } = useSelector((state) => state.chat);
  const [message, setMessage] = useState("");
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [helpkeystatus, setHelpKeyStatus] = useState(false);
  const [fileupload, setFileUpload] = useState();
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionid, setSessionID] = useState("");
  const [negpromptOpen, setNegpromptOpen] = useState(false);
  const [negativeprompts, setnegativeprompts] = useState("");
  const [masktype, setMasktype] = useState("");
  const [maskprompt, setmaskprompt] = useState("");
  const [cfgscale, setCfgscale] = useState();
  const [baseimg, setbaseimg] = useState("");
  const [imagests, setimagests] = useState("no");
  const [fileList, setFileList] = useState([]);
  const [maskCategory, setMaskCategory] = useState("");
  const [premaskCategory, setpreMaskCategory] = useState("");
  const [imgsts, setImasts] = useState(false);
  const {
    avatardetails,
    libraryquestion,
    chat_session_id,
    temp_chat_session_id,
    pinnedstatus,
  } = useSelector((state) => state.chat);
  const [form] = Form.useForm();
  const { chatmessage } = useSelector((state) => state.chat);
  const [messageprocessstatus, setMessageProcessStatus] = useState(true);
  const [pinnedavatarstatus, setPinnedAvatarStatus] = useState(false);
  const dispatch = useDispatch();
  const lastWord = useSelector((state) => state.lastword);

  useEffect(() => {
    if (lastWord) {
      setMessage(lastWord);
      getDetails();
    }
  }, [lastWord]);

  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  useEffect(() => {
    setMessage(libraryquestion);
  }, [libraryquestion]);

  useEffect(() => {
    dispatch(setTempChatSessionID(generateTransactionId()));
    if (avatardetails.pinned_status === "pinned") {
      setPinnedAvatarStatus(true);
    } else {
      setPinnedAvatarStatus(false);
    }
  }, [id]);
  const getDetails = () => {
    getStartConversation();
    setAiButtonStatus(true);
    dispatch(updateLastWord(""));
  };

  const getStartConversation = () => {
    if (messageprocessstatus && message.trim() !== "") {
      var chatsessionid;
      var temp_chatsesssion_id = temp_chat_session_id;
      setMessageProcessStatus(false);
      if (chat_session_id !== "") {
        chatsessionid = chat_session_id;
      } else {
        chatsessionid = generateTransactionId();

        dispatch(setChatSessionID(chatsessionid));
      }

      var temp_chatsesssion_id;
      if (temp_chat_session_id !== "") {
        temp_chatsesssion_id = temp_chat_session_id;
      } else {
        temp_chatsesssion_id = generateTransactionId();
        dispatch(setTempChatSessionID(temp_chatsesssion_id));
      }

      const chatinput = htmlRemove(message);
      var chatmessage = chatinput;
      setMessage("");
      dispatch(setLibraryQuestion(""));

      const avatar_chat_details_user = {
        text: chatinput,
        user: true,
        date: getCurrentDayAndTime(),
        type: "message",
      };
      dispatch(setChatMessage(avatar_chat_details_user));

      const avatar_chat_details_loader = {
        text: "",
        user: false,
        date: getCurrentDayAndTime(),
        type: "loader",
      };
      dispatch(setChatMessage(avatar_chat_details_loader));

      const input = {
        user_session: chatsessionid,
        avatar_id: id,
        chat: chatmessage,
        schema: companyName,
        user_name: userName,
        temp_session_id: temp_chatsesssion_id,
        avatar_name: avatardetails.avatar_name,
        pod_name: avatardetails.pod,
        negative_prompt: id == 750 || id == 760 ? negativeprompts : "",
        cfgscale: id == 750 || id == 760 ? cfgscale : "",
        mask_type: id == 750 || id == 760 ? masktype : "",
        mask_prompt: id == 750 || id == 760 ? maskprompt : "",
        image: id == 750 || id == 760 ? baseimg : "",
        text: id == 750 || id == 760 ? imagests : "",
        mask_category: id == 750 || id == 760 ? maskCategory : "",
      };
      // console.log(input);
      dispatch(startConversation(input))
        .unwrap()
        .then(({ data }) => {
          dispatch(deleteChatMessage());
          // setnegativeprompts("");
          const result = JSON.parse(data.aivolvex_control_plane);
          const responce = result.answer;

          const avatar_chat_details = {
            text: responce,
            user: false,
            date: getCurrentDayAndTime(),
            type: "message",
            message_list: result.document,
            source: true,
            image: avatardetails.avatar_image,
            avatar_name: avatardetails.avatar_name,
            input_url:
              id == 750 || id == 760
                ? result.input_url
                  ? result.input_url
                  : ""
                : "",
            output_url:
              id == 750 || id == 760
                ? result.output_url
                  ? result.output_url
                  : ""
                : "",
            mask_prompt:
              id == 750 || id == 760
                ? result.mask_prompt
                  ? result.mask_prompt
                  : ""
                : "",
            mask_type:
              id == 750 || id == 760
                ? result.mask_type
                  ? result.mask_type
                  : ""
                : "",
            mask_category:
              // responce == "yes" && (id == 750 || id == 760)
              id == 750 || id == 760
                ? result.mask_category
                  ? result.mask_category
                  : ""
                : "",
          };
          dispatch(setChatMessage(avatar_chat_details));
          setMessageProcessStatus(true);
        })
        .catch((err) => {
          console.log(err);
          // setnegativeprompts("");
          dispatch(deleteChatMessage());
          const avatar_chat_details = {
            text: "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?",
            user: false,
            date: getCurrentDayAndTime(),
            type: "message",
            image: avatardetails.avatar_image,
          };
          dispatch(setChatMessage(avatar_chat_details));
          setMessageProcessStatus(true);
        });
    }
  };

  // function getCurrentDayAndTime() {
  //   const options = {
  //     weekday: "short",
  //     hour: "numeric",
  //     minute: "2-digit",
  //     hour12: true,
  //   };
  //   const formatter = new Intl.DateTimeFormat("ist", options);
  //   const currentDate = new Date();
  //   const formattedDate = formatter.format(currentDate);
  //   return formattedDate;
  // }

  // const htmlRemove = (event) => {
  //   const htmlContent = event;
  //   const tempElement = document.createElement("div");
  //   tempElement.innerHTML = htmlContent;
  //   return tempElement.textContent;
  // };

  // function generateTransactionId() {
  //   const timestamp = Date.now().toString();
  //   const randomNum = Math.floor(Math.random() * 10000)
  //     .toString()
  //     .padStart(4, "0");
  //   return `${timestamp}-${randomNum}`;
  // }

  const handleKeyPress = (event) => {
    if (message.length > 0) {
      if (event.key === "Enter") {
        event.preventDefault();
        getStartConversation();
      }
    }
  };
  // function generateRandomFourDigitNumber() {
  //   return Math.floor(1000 + Math.random() * 9000);
  // }

  function generateUniqueID() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const randomFourDigitNumber = generateRandomFourDigitNumber();
    return `${year}${month}${day}-${randomFourDigitNumber}`;
  }
  let timer;
  const stopTimer = () => {
    clearInterval(timer);
  };

  const handleNoResponse = (uniqueID) => {
    let timerId;
    timerId = setInterval(async () => {
      try {
        const input = {
          user_session: temp_chat_session_id,
          company: companyName,
          unique_id: uniqueID,
        };
        const { data } = await dispatch(getFilecheck(input)).unwrap();
        if (JSON.parse(data.aivolvex_control_plane) === "completed") {
          dispatch(deleteChatMessage());
          const avatar_chat_details = {
            text: "File analysis is complete! 'You can now inquire about the file's contents...",
            user: false,
            date: getCurrentDayAndTime(),
            type: "message",
            image: avatardetails.avatar_image,
            message_list: [],
          };
          setMessageProcessStatus(true);
          dispatch(setChatMessage(avatar_chat_details));
          clearInterval(timerId);
        }
      } catch (error) {
        console.error("Error checking file status:", error);
      }
    }, 30000);
  };
  const handleFileUpload = (event) => {
    const files = selectedFiles.map((fileInfo) => ({
      file: fileInfo,
    }));
    const selectedFile = files;
    setFileUpload(selectedFile);
    handlefileOk(selectedFile, temp_chat_session_id);
    setFileModalOpen(false);
    setSelectedFiles([]);
  };
  const handlefileOk = async (selectedFile, temp_chat_session_id) => {
    const uniqueID = generateUniqueID();

    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };

    try {
      const base64Files = await Promise.all(
        selectedFile.map((fileDetail) =>
          convertFileToBase64(fileDetail.file.originFileObj)
        )
      );

      const params = base64Files.map((base64File, index) => ({
        bucket_name: avatardetails.bucket_name,
        file_name: `${temp_chat_session_id}/${uniqueID}/${selectedFile[index].file.name}`,
        file_content: base64File,
        content_type: "multipart/form-data",
      }));

      stopTimer();
      handleAWSFileUpload(params, uniqueID, temp_chat_session_id);
    } catch (error) {
      console.error("Error converting files to base64: ", error);
    }
  };

  const handleAWSFileUpload = async (
    params,
    uniqueID,
    temp_chat_session_id
  ) => {
    try {
      const filegroup = {
        files: params,
      };
      dispatch(multifileuploads3(filegroup))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
      // const uploadPromises = params.map(async (param) => {
      //   await s3.upload(param).promise();
      // });
      setMessageProcessStatus(false);
      const avatar_chat_details = {
        text: "File Uploaded Successfully! currently processing the file. Please wait while it's being analyzed..",
        user: false,
        date: getCurrentDayAndTime(),
        type: "message",
        image: avatardetails.avatar_image,
        message_list: [],
      };
      dispatch(setChatMessage(avatar_chat_details));

      const avatar_chat_details_loader = {
        text: "",
        user: false,
        date: getCurrentDayAndTime(),
        type: "loader",
      };
      dispatch(setChatMessage(avatar_chat_details_loader));

      const input = {
        user_session: temp_chat_session_id,
        company: companyName,
        unique_id: uniqueID,
        avatar_id: id,
      };
      setTimeout(() => {
        dispatch(getEmbeddingfile(input))
          .unwrap()
          .then(({ data }) => {
            const value = JSON.parse(data.aivolvex_control_plane);
            if (value === "embeddings are created") {
              dispatch(deleteChatMessage());
              const avatar_chat_details = {
                text: "File analysis is complete! 'You can now inquire about the file's contents...",
                user: false,
                date: getCurrentDayAndTime(),
                type: "message",
                image: avatardetails.avatar_image,
                message_list: [],
              };
              setMessageProcessStatus(true);
              dispatch(setChatMessage(avatar_chat_details));
            } else {
              handleNoResponse(uniqueID);
            }
          })
          .catch((err) => {
            handleNoResponse(uniqueID);
          });
      }, 2000);
    } catch (error) {
      console.error("Error uploading files to S3:", error);
    }
  };
  const handleCancel = () => {
    setFileModalOpen(false);
    setSelectedFiles([]);
  };
  const handlepromtCancel = () => {
    setNegpromptOpen(false);
    // setnegativeprompts("");
    form.resetFields();
  };
  const setPinnedAvatar = () => {
    var pin_status = "pin";
    if (avatardetails.pinned_status === "pinned") {
      pin_status = "unpin";
    }
    const input = {
      avatar_id: id,
      company: companyName,
      user_id: userName,
      pin_status: pin_status,
    };

    dispatch(pinnedAvatar(input))
      .unwrap()
      .then(({ data }) => {
        dispatch(setPinnedStatus(!pinnedstatus));
      })
      .catch((err) => {
        console.log(err);
      });
    setPinnedAvatarStatus(!pinnedavatarstatus);
  };

  const currentTime = formatCurrentTime();
  const onFinish = (value) => {
    const file = value.image?.fileList[0]?.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setbaseimg(base64String);
        setImasts(true);
      };
      reader.readAsDataURL(file);
    }
    if (value.image_name == "yes" && value.img_mask_category == "yes") {
      setimagests(value.image_name + "-" + "masked");
    } else {
      if (value.image_name == "yes" && value.img_mask_category == "no") {
        setimagests("yes");
      }
      // setimagests(value.image_name || "no");
      if (value.image_name == "no") {
        setimagests("");
      }
    }
    setnegativeprompts(value.negativeprompt);
    setbaseimg(value.image.fileList[0].thumbUrl);
    setmaskprompt(value.mask_prompt ? value.mask_prompt : maskprompt);
    setCfgscale(value.cfgscale);
    setMasktype(value.mask_type);
    setNegpromptOpen(false);
    // setpreMaskCategory
    // form.resetFields();
  };
  const beforeUpload = (file) => {
    console.log(file);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must be smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };
  const validateCfgScale = (rule, value) => {
    if (!value || (value > 0 && value <= 50)) {
      return Promise.resolve();
    }
    return Promise.reject("CFG Scale must be a number between 0 and 50");
  };
  const [imgtag, setImagetag] = useState();
  const handleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
    const file = fileList[0]?.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagetag(reader.result);
        setbaseimg(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };
  // useEffect(()=>{
  //   const file = value.image?.fileList[0]?.originFileObj;
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = reader.result;
  //       setbaseimg(base64String);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // },[])
  const handleMaskCategoryChange = (value) => {
    setMaskCategory(value);
  };
  const handlepreMaskCategoryChange = (value) => {
    setpreMaskCategory(value);
  };
  return (
    <>
      {drawerstatus && (
        <AvatarAboutDrawer
          drawerstatus={drawerstatus}
          setDrawarStatus={setDrawarStatus}
        />
      )}
      <Row className={id == 5 ? "times-font" : "aivolvex-font"}>
        <Col span={24}>
          <Row className="volvex-chat-header">
            <Col span={24}>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <div>
                    <img
                      src={avatardetails.avatar_image}
                      alt="avatar-img"
                      className="volvex-chat-header-avatar-logo"
                    />
                  </div>
                  <div className="volvex-chat-avatar-title">
                    <label className={id == 5 ? "times-font" : "aivolvex-font"}>
                      {avatardetails.avatar_name}
                    </label>

                    <div>
                      <Icon
                        onClick={() => {
                          setPinnedAvatar();
                        }}
                        icon="fluent-mdl2:pinned"
                        className={
                          avatardetails.pinned_status === "pinned"
                            ? "volvex-chat-header-icon cursor-pointer pinned-color"
                            : "volvex-chat-header-icon cursor-pointer"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="volvex-chat-list-body">
            <ChatBody />
          </Row>
          <Row className="volvex-chat-list-footer">
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div class="input-container">
                    <Input.TextArea
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        // if (e.target.value.length > 0) {
                        //   setAiButtonStatus(false);
                        // } else {
                        //   setAiButtonStatus(true);
                        // }
                      }}
                      autoSize={{ minRows: 1 }}
                      className={`input-container-textarea  ${
                        id == 5 ? "times-font" : "aivolvex-font"
                      }`}
                      // className="input-container-textarea aivolvex-font"
                      onKeyPress={handleKeyPress}
                    />
                    {(avatardetails.id == 750 || avatardetails.id == 760) && (
                      <>
                        <span className="custom-file-upload d-flex align-items-center">
                          <Icon
                            icon="ci:chat-add"
                            style={{ fontSize: "20px", color: "#fff" }}
                            onClick={() => setNegpromptOpen(true)}
                          />
                        </span>
                      </>
                    )}
                    {avatardetails.type === "prometheus" ||
                    avatardetails.type === "image" ? (
                      <label
                        htmlFor="file-input"
                        className="custom-file-upload d-flex align-items-center"
                      >
                        <span
                          className="upload-icon"
                          onClick={() => setFileModalOpen(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 35 35"
                            fill="none"
                          >
                            <path
                              d="M9.39779 12.8066H12.9398V23.3141C12.9398 24.3802 13.8042 25.2435 14.8692 25.2435H20.1355C21.2004 25.2435 22.0649 24.3803 22.0649 23.3141V12.8066H25.6057C26.1923 12.8066 26.7196 12.4534 26.9452 11.9113C27.1684 11.3715 27.0442 10.7473 26.6298 10.3329L18.5262 2.22831C17.9602 1.66348 17.0433 1.66348 16.4761 2.22831L8.37391 10.3329C7.95937 10.7473 7.83517 11.3704 8.0596 11.9113C8.28502 12.4524 8.81119 12.8066 9.39779 12.8066Z"
                              fill="white"
                            />
                            <path
                              d="M33.2597 16.7449C32.2995 16.7449 31.5206 17.5239 31.5206 18.4851V25.7617C31.5206 27.9415 29.7461 29.7148 27.5662 29.7148H7.43365C5.25376 29.7148 3.48049 27.9415 3.48049 25.7617V18.4851C3.48049 17.5239 2.70036 16.7449 1.74024 16.7449C0.778989 16.7449 0 17.5239 0 18.4852V25.7618C0 29.8606 3.33467 33.1954 7.43365 33.1954H27.5662C31.6652 33.1954 35 29.8607 35 25.7618V18.4852C35 17.5239 34.2209 16.7449 33.2597 16.7449Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </label>
                    ) : (
                      <></>
                    )}

                    <AudioToText setMessage={setMessage} />
                    <button
                      // disabled={aibuttonstatue}
                      // className={aibuttonstatue && "disable-ai-btn"}
                      onClick={() => getStartConversation()}
                      className="volvex-submit-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 41 41"
                        fill="none"
                        className="volvex-submit-btn-icon"
                      >
                        <path
                          d="M3.84375 35.875L37.1562 20.5L3.84375 5.125V17.0833L27.707 20.5L3.84375 23.9167V35.875Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                {/* <Col span={2}>
                  <div className="volvex-chat-token-cal d-flex justify-content-center align-items-center">
                    <div className="volvex-chat-total-token">10</div>
                    <div className="volvex-chat-token-logo d-flex  align-items-center">
                      <img
                        src={aivolvex}
                        alt="logo"
                        className="volvex-chat-token-logo-img"
                      />
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal open={fileModalOpen} footer={false} onCancel={handleCancel}>
        <>
          <Upload
            multiple
            fileList={selectedFiles}
            className="upload-file-pdf"
            onChange={(info) => {
              if (info.file.type === "application/pdf") {
                setSelectedFiles(info.fileList);
                setErrorMessage("");
              } else {
                setErrorMessage("Only PDF files are allowed for upload.");
              }
            }}
          >
            <Button className="select-file">
              <Icon icon="line-md:upload-loop" className="upload-file-icon" />
              Select Files
            </Button>
            <br />
          </Upload>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <br />
          {selectedFiles.length > 0 && (
            <Button
              onClick={handleFileUpload}
              type="primary"
              className="upload-file "
            >
              Upload Files
            </Button>
          )}
        </>
      </Modal>
      <Modal
        open={negpromptOpen}
        footer={false}
        onCancel={handlepromtCancel}
        width={1000}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{ image_name: "no" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="image" label="Image">
                <Upload
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  fileList={fileList}
                  listType="picture"
                  className="image-upload"
                >
                  {fileList.length < 1 && (
                    <Button icon={<Icon icon="ep:upload-filled" />}>
                      Click to Upload image
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            </Col>

            {baseimg && imgsts && (
              <Col span={12} className="px-1">
                <Form.Item
                  name="image_name"
                  label="Do you want to continue with output image?"
                >
                  <Select
                    placeholder="Do you want to continue with output image?"
                    optionFilterProp="children"
                    defaultValue="no"
                    options={[
                      {
                        value: "yes",
                        label: "YES",
                      },
                      {
                        value: "no",
                        label: "No",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12} className="px-1">
              <Form.Item name="mask_type" label="Mask Type">
                <Select
                  placeholder="Select mask type"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setMasktype(value);
                  }}
                  options={[
                    {
                      value: "OUTPAINTING",
                      label: "Outpainting",
                    },
                    {
                      value: "INPAINTING",
                      label: "Inpainting",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12} className="px-1">
              <Form.Item name="mask_category" label="Mask Category">
                <Select
                  placeholder="Select mask category"
                  optionFilterProp="children"
                  onChange={handleMaskCategoryChange}
                  options={[
                    {
                      value: "sam_model",
                      label: "SAM Masking Model",
                    },
                    {
                      value: "mask_prompt",
                      label: "Mask Prompt",
                    },
                    { value: "custom_mask", label: "Custom  Mask" },
                  ]}
                />
              </Form.Item>
            </Col>
            {baseimg && imgsts && 
            (maskCategory === "sam_model" || maskCategory === "custom_mask") ? (
              <Col span={12} className="px-1">
                <Form.Item
                  name="img_mask_category"
                  label="Do you want to continue with previous masking?"
                >
                  <Select
                    placeholder="Select previous masking category"
                    optionFilterProp="children"
                    onChange={handlepreMaskCategoryChange}
                    options={[
                      {
                        value: "yes",
                        label: "Yes",
                      },
                      {
                        value: "no",
                        label: "No",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            ) : null}

            {/* 
            {baseimg &&
              maskCategory &&
              (maskCategory == "sam_model" || maskCategory == "custom_mask")(
                <Col span={12} className="px-1">
                  <Form.Item
                    name="img_mask_category"
                    label="Do you want to continue with previous masking?"
                  >
                    <Select
                      placeholder="Select previous masking category"
                      optionFilterProp="children"
                      onChange={handlepreMaskCategoryChange}
                      options={[
                        {
                          value: "yes",
                          label: "Yes",
                        },
                        {
                          value: "no",
                          label: "No",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )} */}

            {maskCategory === "sam_model" && fileList.length > 0 && (
              <Row style={{ width: "100%", height: "100%" }}>
                <Col span={24}>
                  <ImageSelections
                    imgtag={imgtag}
                    setmaskprompt={setmaskprompt}
                  />
                </Col>
              </Row>
            )}

            {maskCategory === "mask_prompt" && (
              <Col span={12} className="px-1">
                <Form.Item label="Mask Prompt" name="mask_prompt">
                  <Input value={maskprompt} />
                </Form.Item>
              </Col>
            )}
            {maskCategory === "custom_mask" && fileList.length > 0 && (
              <Row style={{ width: "100%", height: "100%" }}>
                <Col span={24}>
                  <Custommask imgtag={imgtag} setmaskprompt={setmaskprompt} />
                </Col>
              </Row>
            )}

            {/* {fileList.length > 0 && (
              <Row style={{ width: "100%", height: "100%" }}>
                <Col span={24}>
                  <ImageSelections imgtag={imgtag} />
                </Col>
              </Row>
            )} */}
            {/* <Col span={12} className="px-1">
              <Form.Item label="Mask Prompt" name="mask_prompt">
                <Input value={maskprompt} />
              </Form.Item>
            </Col> */}
            <Col span={12} className="px-1">
              <Form.Item
                label="CFG Scale"
                name="cfgscale"
                rules={[
                  {
                    validator: validateCfgScale,
                  },
                ]}
              >
                <Input type="number" min={0} max={50} step={0.1} />
              </Form.Item>
            </Col>
            <Col span={12} className="px-1">
              <Form.Item label="Negative Prompt" name="negativeprompt">
                <Input value={negativeprompts} />
              </Form.Item>
            </Col>
            <Col span={24} className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Done
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      ;
    </>
  );
};

export default memo(ChatPart);
