import { memo, useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import PodList from "../Desktop/PodList";

const MobilePodList = (props) => {
    return (<>
        <Row>
            <Col >
                <PodList  />
            </Col>
        </Row>
    </>)
}
export default MobilePodList;