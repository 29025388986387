import { memo, useEffect, useState } from "react";
import RecentPromptsDesktop from "./Desktop/RecentPromptsDesktop";
import RecentPromptsMobile from "./Mobile/RecentPromptsMobile"
const RecentPromptsBody = () => {
    return (
        <>
            <div className="conversation-desktop">
                <RecentPromptsDesktop />
            </div>
            <div className="conversation-mobile">
                <RecentPromptsMobile />
            </div>

        </>
    )
}
export default RecentPromptsBody;