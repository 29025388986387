import { Row, Col, Tag, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { capitalizeWords } from "../../customhook/CustomHoom";
import MarkdownToJSX from "markdown-to-jsx";
import PopConfirmQa from "./PopConfirmQa";
const details = (props) => {
  const data = props.records;
  return (
    <>
      <Row>
        <Col span={12}>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Name:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.name)}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Email:
            </Col>
            <Col span={12} className="white-font question-font">
              {data.email}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Phone:
            </Col>
            <Col span={12} className="white-font question-font">
              {data.phone}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Lanugage:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.language)}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Type:
            </Col>
            <Col span={12} className="white-font question-font">
              <Tag
                color={
                  data.typeofconversation === "chat" ||
                  data.typeofconversation === "chat_v1"
                    ? "blue"
                    : data.typeofconversation === "voice"
                    ? "purple"
                    : "default"
                }
              >
                {data.typeofconversation === "chat_v1"
                  ? "Voice"
                  : capitalizeWords(data.typeofconversation)}
              </Tag>
            </Col>
          </Row>
          {props.avatardetails.chat_type == "voice" &&
          data.typeofconversation !== "chat_v1" ? (
            <>
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  QA Check:
                </Col>
                <Col span={12}>
                  <div>
                    {(() => {
                      let iconColor, iconName, textColor;
                      switch (data.qa_status) {
                        case "Incompleted":
                          iconColor = "red";
                          iconName = "ion:sad";
                          textColor = "red";
                          break;
                        case "Completed":
                          iconColor = "#0BB852";
                          iconName = "mdi:smiley";
                          textColor = "#0BB852";
                          break;
                        default:
                          iconColor = "#fff";
                          iconName = "ph:smiley-fill";
                      }

                      return (
                        <Popconfirm
                          className="record-dashboard-popup"
                          placement="top"
                          title=""
                          description=<PopConfirmQa content={data.qa_content} />
                          footer={false}
                          okText=""
                          cancelText=""
                          cancelButtonProps={{ style: { display: "none" } }}
                          okButtonProps={{ style: { display: "none" } }}
                        >
                          <>
                            <span className="cursor">
                              <Icon
                                icon={iconName}
                                style={{ color: iconColor, fontSize: "24px" }}
                              />
                            </span>
                            <span
                              style={{ color: textColor }}
                              className="smiley-text cursor"
                            >
                              {capitalizeWords(data.qa_status)}
                            </span>
                          </>
                        </Popconfirm>
                      );
                    })()}
                  </div>
                </Col>
              </Row>
              {data.voice_url && (
                <>
                  <Row className="p-2">
                    <Col span={12} className="puple-font question-font">
                      Audio:
                    </Col>
                    <Col span={12}>
                      <div>
                        <audio
                          controls
                          controlsList="nodownload"
                          style={{
                            width: "100%",
                            maxWidth: "300px",
                            height: "2rem",
                          }}
                          src={data.voice_url}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <Row className="p-2">
                {data.typeofconversation !== "chat_v1" && (
                  <>
                    <Col span={12} className="puple-font question-font">
                      Handedoff:
                    </Col>
                    <Col span={12}>
                      <div>
                        {(() => {
                          let iconColor, iconName, textColor;
                          switch (data.handed_off) {
                            case "Negative":
                              iconColor = "red";
                              iconName = "ion:sad";
                              textColor = "red";
                              break;
                            case "Positive":
                              iconColor = "#0BB852";
                              iconName = "mdi:smiley";
                              textColor = "#0BB852";
                              break;

                            default:
                              iconColor = "#fff";
                              iconName = "ph:smiley-fill";
                          }

                          return (
                            <Popconfirm
                              className="record-dashboard-popup"
                              placement="top"
                              title=""
                              description=<MarkdownToJSX>
                                {data.handed_off_explanation}
                              </MarkdownToJSX>
                              footer={false}
                              okText=""
                              cancelText=""
                              cancelButtonProps={{ style: { display: "none" } }}
                              okButtonProps={{ style: { display: "none" } }}
                            >
                              <>
                                <span className="cursor">
                                  <Icon
                                    icon={iconName}
                                    style={{
                                      color: iconColor,
                                      fontSize: "24px",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: textColor }}
                                  className="smiley-text cursor"
                                >
                                  {capitalizeWords(data.handed_off)}
                                </span>
                              </>
                            </Popconfirm>
                          );
                        })()}
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}
        </Col>
        <Col span={12}>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Service:
            </Col>
            <Col span={12} className="white-font question-font">
              {data.service}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Service category:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.category)}
            </Col>
          </Row>
          {data.typeofconversation !== "chat_v1" && (
            <>
              {" "}
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  Sentiment:
                </Col>
                <Col span={12}>
                  <div>
                    {(() => {
                      let iconColor, iconName, textColor;

                      switch (data.sentiment) {
                        case "Positive":
                          iconColor = "#0BB852";
                          iconName = "mdi:smiley";
                          textColor = "#0BB852";
                          break;
                        case "Neutral":
                          iconColor = "#FFC400";
                          iconName = "ph:smiley-meh-fill";
                          textColor = "#FFC400";
                          break;
                        case "Negative":
                          iconColor = "#FF2441";
                          iconName = "ion:sad";
                          textColor = "#FF2441";
                          break;
                        default:
                          iconColor = "black";
                          iconName = "ph:smiley-fill";
                      }

                      return (
                        <Popconfirm
                          className="record-dashboard-popup"
                          placement="top"
                          title=""
                          description=<MarkdownToJSX>
                            {data.sentiment_explanation}
                          </MarkdownToJSX>
                          footer={false}
                          okText=""
                          cancelText=""
                          cancelButtonProps={{ style: { display: "none" } }}
                          okButtonProps={{ style: { display: "none" } }}
                        >
                          {data.sentiment && (
                            <>
                              <span className="cursor">
                                <Icon
                                  icon={iconName}
                                  style={{ color: iconColor, fontSize: "24px" }}
                                />
                              </span>
                              <span
                                style={{ color: textColor }}
                                className="smiley-text cursor"
                              >
                                {capitalizeWords(data.sentiment)}
                              </span>
                            </>
                          )}
                        </Popconfirm>
                      );
                    })()}
                  </div>
                </Col>
              </Row>
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  Summary:
                </Col>
                <Col span={12}>
                  <div>
                    {(() => {
                      let iconColor, iconName, textColor;

                      switch (data.summary) {
                        case "Positive":
                          iconColor = "#0BB852";
                          iconName = "mdi:smiley";
                          textColor = "#0BB852";
                          break;
                        case "Neutral":
                          iconColor = "#FFC400";
                          iconName = "ph:smiley-meh-fill";
                          textColor = "#FFC400";
                          break;
                        case "Negative":
                          iconColor = "#FF2441";
                          iconName = "ion:sad";
                          textColor = "#FF2441";
                          break;
                        default:
                          iconColor = "#fff";
                          iconName = "ph:smiley-fill";
                      }

                      return (
                        <Popconfirm
                          className="record-dashboard-popup"
                          placement="top"
                          title=""
                          description=<MarkdownToJSX>
                            {data.summary}
                          </MarkdownToJSX>
                          footer={false}
                          okText=""
                          cancelText=""
                          cancelButtonProps={{ style: { display: "none" } }}
                          okButtonProps={{ style: { display: "none" } }}
                        >
                          {/* {data.sentiment && (
                                                <>
                                                    <span> */}
                          <Icon
                            icon="fluent:notepad-edit-20-regular"
                            style={{ color: iconColor, fontSize: "24px" }}
                            className="cursor"
                          />
                          {/* </span>
                                                    <span style={{ color: textColor }} className="smiley-text">
                                                        {capitalizeWords(data.sentiment)}
                                                    </span>
                                                </>
                                            )} */}
                        </Popconfirm>
                      );
                    })()}
                  </div>
                </Col>
              </Row>
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  Oppurtunity:
                </Col>
                <Col span={12}>
                  <div>
                    {(() => {
                      let iconColor, iconName, textColor;

                      switch (data.lead) {
                        case "Yes":
                          iconColor = "#0BB852";
                          iconName = "mdi:smiley";
                          textColor = "#0BB852";
                          break;
                        case "Neutral":
                          iconColor = "#FFC400";
                          iconName = "ph:smiley-meh-fill";
                          textColor = "#FFC400";
                          break;
                        case "No":
                          iconColor = "#FF2441";
                          iconName = "ion:sad";
                          textColor = "#FF2441";
                          break;
                        default:
                          iconColor = "black";
                          iconName = "ph:smiley-fill";
                      }

                      return (
                        <Popconfirm
                          className="record-dashboard-popup"
                          placement="top"
                          title=""
                          description=<MarkdownToJSX>
                            {data.lead_explanation}
                          </MarkdownToJSX>
                          footer={false}
                          okText=""
                          cancelText=""
                          cancelButtonProps={{ style: { display: "none" } }}
                          okButtonProps={{ style: { display: "none" } }}
                        >
                          {data.sentiment && (
                            <>
                              <span className="cursor">
                                <Icon
                                  icon={iconName}
                                  style={{ color: iconColor, fontSize: "24px" }}
                                />
                              </span>
                              <span
                                style={{ color: textColor }}
                                className="smiley-text cursor"
                              >
                                {capitalizeWords(data.lead)}
                              </span>
                            </>
                          )}
                        </Popconfirm>
                      );
                    })()}
                  </div>
                </Col>
              </Row>
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  Action:
                </Col>
                <Col span={12}>
                  {data.action_to_be_taken != null && (
                    <div>
                      {(() => {
                        return (
                          <Popconfirm
                            className="record-dashboard-popup"
                            placement="top"
                            title=""
                            description=<MarkdownToJSX>
                              {data.action_to_be_taken}
                            </MarkdownToJSX>
                            footer={false}
                            okText=""
                            cancelText=""
                            cancelButtonProps={{ style: { display: "none" } }}
                            okButtonProps={{ style: { display: "none" } }}
                          >
                            <Icon
                              icon="eos-icons:role-binding-outlined"
                              style={{ color: "#fff", fontSize: "24px" }}
                              className="cursor"
                            />
                          </Popconfirm>
                        );
                      })()}
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
          {data.typeofconversation == "chat_v1" && (
            <>
              {" "}
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  Reason for visit:
                </Col>
                <Col span={12} className="white-font question-font">
                  <div>{data.reason_for_visit}</div>
                </Col>
              </Row>
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                  Assessment:
                </Col>
                <Col span={12} className="white-font question-font">
                  <div>{data.assessment}</div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default details;
