import { memo } from "react";

const EmailContent = (props) => {
  return (
    <>
      <pre className="aivolvex-font content-formate">
        <div
          dangerouslySetInnerHTML={{
            __html: props.records.email_content,
          }}
        />
      </pre>
    </>
  );
};
export default memo(EmailContent);
