import { memo } from "react";
import { Icon } from "@iconify/react";

const PopConfirmQa = (props) => {
  return (
    <>
      <div className="volvex-shared-form">
        {JSON.parse(props.content).map((data, index) => {
          {
            console.log(index);
          }
          return (
            <>
              <p>
                {index + 1}. {data.content}
                {data.status === "yes" ? (
                  <Icon
                    icon="mdi:tick-circle-outline"
                    className="qa-check-tick"
                  />
                ) : (
                  <Icon
                    icon="iconoir:xmark-circle"
                    className="qa-check-cross"
                  />
                )}
              </p>
            </>
          );
        })}
        {/* {props.content}
        {props.status === "yes" ? (
          <Icon icon="teenyicons:tick-circle-solid" />
        ) : (
          <Icon icon="gravity-ui:circle-xmark-fill" />
        )} */}
      </div>
    </>
  );
};

export default memo(PopConfirmQa);
