import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Tokenmain from "../Desktop/Tokenmain"
import { Col, Row } from "antd";
const TokenBodyMobile = () => {
    return (
        <>
            <>
                {/* <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    <div className="chat-header-part">
                        <Row className="chat-header-part">
                            <Col className="chat-header-logo-title" span={12}> Credits</Col>
                            <Col span={12}>
                                <img
                                    src={navLogo}
                                    alt="nav-logo "
                                    className="navlogo cursor-pointer"
                                />
                            </Col>
                        </Row>
                    </div>

                    <div style={{ flex: 1, overflowY: 'auto' }}>
                        <Row>
                            <Col span={24}>
                                <Tokenmain />
                            </Col>
                        </Row>
                    </div>

                    <div className="chat-bottom-section" >
                        <Row className="chat-bottom-section">
                            <Col span={24}>
                                <SidemenuMobile />
                            </Col>
                        </Row>
                    </div>
                </div> */}
                <Row className="chat-header-part">
                    <Col className="chat-header-logo-title">Credits</Col>
                    <Col>
                        <img
                            src={navLogo}
                            alt="nav-logo "
                            className="navlogo cursor-pointer"
                        />
                    </Col>
                </Row>
                <Row className="recent-mobile-body">
                    {/* <Col span={24}> */}
                        <Tokenmain />
                    {/* </Col> */}
                </Row>
                <Row className="chat-bottom-section">
                    <Col span={24} ><SidemenuMobile /></Col>
                </Row>
            </>
        </>
    )
}
export default TokenBodyMobile