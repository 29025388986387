import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  gql,
  concat,
} from "@apollo/client";

const httpLink = new HttpLink({
  uri: "https://buqcjvlz7rcbhmmt4q7na3yaqm.appsync-api.ap-south-1.amazonaws.com/graphql",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-api-key": "da2-75knvkkmy5gybbmfy57ve3prdu",
    },
  }));

  return forward(operation);
});

const without_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default without_client;
