import { Col, Row, Tabs } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import preloader from "../../../assets/loader/pre_loader.gif";
import TagMemberConfig from "../Desktop/TagMemberConfig";
import '../CSS/rolehome.css';
import TagAvatarConfig from "../Desktop/TagAvatarConfig";
import { listTagging, getAvatarList } from "../../api/accountAction";
const Taghomemobile = () => {
    const [basicActive, setBasicActive] = useState("0");
    const [datachangestatus, setDataChangeStatus] = useState(true);
    const [preloaderstatus, setPreLoaderStatus] = useState(true);
    const [records, setRecords] = useState([]);
    const dispatch = useDispatch();
    const [avatarlist, setAvatarList] = useState([]);
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );

    useEffect(() => {
        setPreLoaderStatus(true);
        const input = {
            compnayname: companyName,
        };
        dispatch(listTagging(input))
            .unwrap()
            .then(({ data }) => {
                setPreLoaderStatus(false);
                const responce = JSON.parse(data.aivolvex_control_plane);

                const resultArray = responce.map((item) => ({
                    id: item.id,
                    title: item.tag_title,
                    members_list: splitMembers(item.member_list),
                    avatar_list: item.avatar_name.map((email, index) => ({
                        avatar_id: index + 1,
                        avatar: email,
                    })),
                }));
                setRecords(resultArray);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [datachangestatus]);

    useEffect(() => {
        const input = {
            companyName: companyName,
        };
        dispatch(getAvatarList(input))
            .unwrap()
            .then(({ data }) => {
                // console.log(data)
                setAvatarList(JSON.parse(data.aivolvex_control_plane));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    function splitMembers(members) {
        return members.split(",").map((email, index) => ({
            members_id: index + 1,
            members_email: email,
        }));
    }

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
    };
    const items = [
        {
            key: 1,
            label: "Members",
            children: (
                <TagMemberConfig
                    records={records}
                    datachangestatus={datachangestatus}
                    setDataChangeStatus={setDataChangeStatus}
                    avatarlist={avatarlist}
                />
            ),
            className: "plat-dashboard-link",
            onClick: () => handleBasicClick(1),
            active: basicActive === 1,
        },
        {
            key: 2,
            label: "Agents",
            children: (
                <TagAvatarConfig
                    records={records}
                    avatarlist={avatarlist}
                    datachangestatus={datachangestatus}
                    setDataChangeStatus={setDataChangeStatus}
                />
            ),
            className: "plat-dashboard-link",
            onClick: () => handleBasicClick(2),
            active: basicActive === 2,
        },
    ];
    return (
        <>
            <Row className="chat-header-part">
                <Col className="chat-header-logo-title"> Tag</Col>
                <Col>
                    <img
                        src={navLogo}
                        alt="nav-logo "
                        className="navlogo cursor-pointer"
                    />
                </Col>
            </Row>
            <Row className="recent-mobile-body">
                <Col span={24}>
                    {preloaderstatus ? (
                        <div className="aivolvex-loader">
                            <img src={preloader} width="30%" />
                        </div>
                    ) : (
                        // <Row>
                        //     <Col span={24}>
                                <Tabs
                                    className="tag-tabs aivolvex-font"
                                    items={items}
                                    onChange={handleBasicClick}
                                />
                        //     </Col>
                        // </Row>
                    )}
                </Col>
            </Row>
            <Row className="chat-bottom-section">
                <Col span={24} ><SidemenuMobile /></Col>
            </Row>
        </>
    )
}
export default Taghomemobile;