import { memo, useEffect, useState } from "react";
import { Col, Row, Space, Table, Modal, Input, Button, Popconfirm, message, Dropdown } from "antd";
import { getAvatarChatHistory, getHistorylist, gethistoryPageination, deletehistory, updatehistory } from "../../../components/api/accountAction"
import { Icon } from "@iconify/react";
import jsPDF from "jspdf";
import "../CSS/history.css"
import { IoTrashBin } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
const Historymobiletable = () => {
    const dispatch = useDispatch();
    const [count, setCount] = useState("")
    const [records, setRecord] = useState([])
    const [editTitle, setEditTitle] = useState("");
    const [editIndex, setEditIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [status, setStatus] = useState(false)
    const [user, setUser] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [deletemodal, setdeletemodal] = useState(false);
    const [deleterecord, setDeleterecord] = useState("")
    const [preloaderstatus, setPreloaderStatus] = useState(false);
    // const lastWord = useSelector((state) => state.lastword);
    const navigate = useNavigate();
    const companyName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.com")
    );
    const userName = atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );
    const columns = [
        {
            title: "Title",
            dataIndex: "prompt",
            key: "prompt",
            render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,

        },
        {
            title: "Date and Time",
            dataIndex: "dateandtime",
            key: "dateandtime",
            render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{formatDate(text)}</span>,

        },

        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: "right",
            render: (text, record, index) => (

                <Space size="middle">
                    <span>
                        <Button className="his-button" onClick={() => handleEditClick(record)}>
                            <Icon
                                icon="wpf:edit"

                            /> <span className="his-delete-text">Edit</span></Button> </span>
                    <span>

                        <Button className="his-delete" onClick={() => handleremove(record)}>
                            <IoTrashBin style={{ fontSize: "18px" }} />
                            <span className="his-delete-text">Delete</span>
                        </Button>
                    </span>
                    <span>
                        <Button className="his-button history_btn" onClick={() => handleIconClick(record)} > <Icon icon="gg:link" style={{ fontSize: "25px", color: "#fff" }} />
                        </Button>
                    </span>

                    <span >
                        <Button className="his-button history_btn" onClick={() => handlePdfIconClick(record)}> <Icon
                            icon="vscode-icons:file-type-pdf2"
                            style={{ fontSize: "25px" }}
                        /></Button>
                    </span>

                </Space>
            ),
        },
    ];

    const handleremove = (record) => {
        setDeleterecord(record)
        setdeletemodal(true)
    }
    const handleOk = () => {
        setIsModalOpen(false);
        const input = {
            user_id: userName,
            company: companyName,
            user_session: user.session_id,
            avatar_id: user.avatar_id,
            title: editTitle,
        };
        dispatch(updatehistory(input))
            .unwrap()
            .then(({ data }) => {
                const records = JSON.parse(data.aivolvex_control_plane);
                setStatus(true)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const cancel = (e) => {
        setdeletemodal(false)
    };
    const handleEditClick = (record) => {
        setEditTitle(record.prompt);
        setIsModalOpen(true);
        setUser(record)

    };
    useEffect(() => {
        const input = {
            user_id: userName,
            company: companyName
        }
        dispatch(gethistoryPageination(input))
            .unwrap()
            .then(({ data }) => {
                const value = JSON.parse(data.aivolvex_control_plane)[0];
                setCount(value)

            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const formatDate = (inputDate) => {
        if (inputDate) {
            const date = new Date(inputDate);
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const amOrPm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12;
            const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
            return formattedDate;
        }

    };

    const handleDeleteClick = () => {
        const record = deleterecord
        setdeletemodal(false)
        const input = {
            user_id: userName,
            company: companyName,
            user_session: record.session_id,
        };
        dispatch(deletehistory(input))
            .unwrap()
            .then(({ data }) => {
                const records = JSON.parse(data.aivolvex_control_plane);
                setStatus(true)
                message.success("Deleted")

            })
            .catch((err) => {
                console.log(err);

            });
    };
    useEffect(() => {
        setPreloaderStatus(true);
        const inputlist = {
            page: currentPage,
            limit: pageSize,
            user_id: userName,
            company: companyName
        }
        dispatch(getHistorylist(inputlist))
            .unwrap()
            .then(({ data }) => {
                const value = JSON.parse(data.aivolvex_control_plane)
                // console.log(value)
                setPreloaderStatus(false);
                const table = value.map((datas) => {
                    return {
                        // pod: datas.pod,
                        // avatar_name: datas.avatar_name,
                        dateandtime: datas.timestamp,
                        user_id: datas.user_email_id,
                        prompt: datas.title,
                        avatar_id: datas.avatar_id,
                        session_id: datas.session_id,
                    }
                })
                setRecord(table)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentPage, status]);
    const [svgBase64, setSvgBase64] = useState(null);

    useEffect(() => {
        // Create an image element
        const img = new Image();
        img.src = aivolvex;

        // Ensure the image has loaded
        img.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the SVG on the canvas
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);

            // Get the base64 data URL
            const dataURL = canvas.toDataURL('image/png');
            // console.log(dataURL)
            setSvgBase64(dataURL);
        };
    }, []);
    const handlePdfIconClick = (record) => {
        const inputlist = {
            conversationid: record.session_id,
            companyname: companyName,
        };
        dispatch(getAvatarChatHistory(inputlist))
            .unwrap()
            .then(({ data }) => {
                const value = JSON.parse(data.aivolvex_control_plane)
                const pdfrecord = value
                const randomFilename = Math.floor(Math.random() * 1000000).toString();
                const filename = `Aivolvex-history-${randomFilename}.pdf`;
                const doc = new jsPDF();
                const maxWidth = 160;
                let yPosition = 10;

                pdfrecord.forEach((record, index) => {

                    const { question, answer, timestamp, user_id, avatar_name } = record;
                    const lines = doc.splitTextToSize(answer, maxWidth);
                    const answerHeight = lines.length * 10;
                    if (yPosition + answerHeight + 50 > doc.internal.pageSize.height) {
                        doc.addPage();
                        yPosition = 10;
                    }
                    // doc.addImage(svgBase64, 'PNG', 10, 10, 20, 20);
                    doc.text(`Record ${index + 1}:`, 10, yPosition);
                    if (user_id) {
                        doc.text(`${user_id}: ${question}`, 20, yPosition + 10);
                    } else {
                        doc.text(`Question: ${question}`, 20, yPosition + 10);
                    }

                    if (avatar_name) {
                        doc.text(`${avatar_name}:`, 20, yPosition + 20);
                    } else {
                        doc.text(`Answer:`, 20, yPosition + 20);
                    }

                    const date = new Date(timestamp);
                    function padZero(num) {
                        return num < 10 ? `0${num}` : num;
                    }
                    function formatAMPM(date) {
                        const hours = date.getHours();
                        const minutes = date.getMinutes();
                        const ampm = hours >= 12 ? 'PM' : 'AM';
                        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
                        return `${formattedHours}:${padZero(minutes)}${ampm}`;
                    }
                    const formattedTimestamp = `${padZero(date.getDate())}-${padZero(date.getMonth() + 1)}-${date.getFullYear()} ${formatAMPM(date)}`
                    doc.text(lines, 20, yPosition + 30);
                    doc.text(`Date and Time:${formattedTimestamp}`, 20, yPosition + 30 + answerHeight);
                    yPosition += answerHeight + 50;
                });
                doc.save(filename);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };
    const handleIconClick = (record) => {
        // console.log(record)
        const avatarId = record.avatar_id
        const sessionId = record.session_id
        navigate(`/conversation/${avatarId}/${sessionId}`);
    };
    const filteredPrompts = records.filter((item) =>
        item.prompt.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const items = [
        {
            label: '1st menu item',
            key: '1',
        },
        {
            label: '2nd menu item',
            key: '2',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ];
    return (
        <>
            <Col span={24} >

                <Row >
                    {preloaderstatus ? (
                        <>
                            <Col span={24}>
                                <div className="aivolvex-sub-loader h-18">
                                    <img src={preloader} width="20%" />
                                </div>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col span={24} >
                                <Table
                                    className="mobile-view-table"
                                    dataSource={filteredPrompts}
                                    columns={columns}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        total: count,
                                        onChange: handlePageChange,
                                    }}
                                />
                            </Col>
                        </>)}
                </Row>
            </Col>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="edit-button">
                <input
                    type="text"
                    value={editTitle}
                    className="table-input"
                    onChange={(e) => setEditTitle(e.target.value)}
                />
            </Modal>
            <Modal open={deletemodal} onOk={handleDeleteClick}
                onCancel={cancel} className="history-delete-modal">
                <p>Are you sure to delete this title?</p>
            </Modal>
        </>
    )
}
export default Historymobiletable;