import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "./client";
import without_client from "./without_client";
import { useErrorNavigation } from "../customhook/CustomHoom";
import {
  ONBOARD_AVATAR,
  LIST_AVATAR,
  UPDATE_AVATAR,
  GET_AVATAR_DETAILS,
  GET_START_CONVERSATION,
  LOGIN_EMAIL_CHECK,
  GET_START_CONVERSATION_CLOUDSHIFU,
  AZUREADEMAILCHECK,
  GET_START_CONVERSATION_KENDRA,
  AVATARFREQUENTLYPROMPTS,
  AVATARHISTORYTITLE,
  AVATARCHATHISTORY,
  CUSTOMERDETAILS,
  GETTOKENREMAINING,
  KENDRA_OPENAI,
  GET_START_CONVERSATION_PG_VECTOR,
  GET_START_CONVERSATION_GENERAL_CHAT,
  AVATAR_TAG_MEMBERS_LIST,
  SHARE_NEW_AVATRA,
  INSERT_NEW_FEEDBACK,
  GET_AVATAR_LIBRARY_LIST,
  GET_TAG_LIST,
  INSERT_NEW_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  GET_AVALATAR_LIST,
  HISTORY_EDIT,
  HISTORY_DELETE,
  GENERATE_TABLE_CHART,
  USER_CONTROLLER,
  EMBEDDING_FILE,
  EMBEDDING_FILE_CHECK,
  GET_PAGEINATION,
  GET_RECENT_PROMPTS,
  GET_PAGEINATION_HISTORY,
  GET_HISTORY_LIST,
  AVATAR_PINNED,
  LIST_PINNED,
  GET_CREDIT_MONTH,
  GET_TOKEN_AGENT,
  GET_MODAL_CHART,
  TOKEN_CREDIT,
  TOKEN_CREDIT_UPDATE,
  TOKEN_LOG,
  AGENT_LIST,
  AGENT_LIST_INSERT,
  AGENT_LIST_UPDATE,
  AGENT_LOG,
  AGENT_DELETE,
  GET_CREDIT_DAY,
  GET_PROMPT_DAY,
  GET_NO_OF_AGENTS,
  GET_ALLOCATED_TOKEN,
  GET_TOKEN_REQUEST,
  GET_TOTAL_PROMPTS,
  GET_TOP_USER,
  GET_USAGE_YEAR,
  WELCOME_EMAIL,
  S3_DOCUMENT_LIST,
  PUBLIC_LIST_AVATAR,
  HISTORY_RECORD_LIST,
  HISTORY_RECORD_VIEW_LIST,
  GENERATEDQSEARCHURL,
  HISTORY_RECORD_LIST_HEALTH,
  CHAT_RESPONCE_EMAIL,
  ADD_FILE_UPLOAD,
  // HISTORY_RECORD_SUMMARY_LIST,
  // HISTORY_RECORD_OPPORTUNITY_LIST,
} from "./accountquery";

export const onboardAvatar = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: ONBOARD_AVATAR,
        variables: {
          about: data.about,
          avatar_title: data.avatar_title,
          context: data.context,
          pod_category: data.pod_category,
          // prompt : data.prompt,
          avatar_image: data.image,
          version: data.version,
          avatar_owner: data.avatar_owner,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const welcomeEmail = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const signupemail = await without_client.mutate({
        mutation: WELCOME_EMAIL,
        variables: {
          user_id: data.email,
        },
      });
      return signupemail;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAvatar = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: LIST_AVATAR,
        variables: {
          company_name: data.company_name,
          user_id: data.user_id,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarLibrary = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_AVATAR_LIBRARY_LIST,
        variables: {
          company_name: data.companyname,
          id: data.id,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const multifileuploads3 = createAsyncThunk(
  // action type string
  "fileupload/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const s3fileupload = await client.mutate({
        mutation: ADD_FILE_UPLOAD,
        variables: {
          input: data,
        },
      });
      return s3fileupload;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarDetails = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_AVATAR_DETAILS,
        variables: {
          id: data.id,
          user_id: data.user_id,
          // avatar_title: data.avatar_title,
          // context: data.context,
          // pod_category: data.pod_category,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversation = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION,
        variables: {
          user_session: data.user_session,
          avatar_id: data.avatar_id,
          chat: data.chat,
          schema: data.schema,
          user_name: data.user_name,
          avatar_name: data.avatar_name,
          pod_name: data.pod_name,
          temp_session_id: data.temp_session_id,
          negative_prompt: data.negative_prompt,
          cfgscale: data.cfgscale,
          mask_type: data.mask_type,
          mask_prompt: data.mask_prompt,
          image: data.image,
          text: data.text,
          mask_category:data.mask_category
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationCloudShifu = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_CLOUDSHIFU,
        variables: {
          date_time: data.date_time,
          user_session: data.user_session,
          chat: data.chat,
          schema: data.schema,
          user_name: data.user_name,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationKendra = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_KENDRA,
        variables: {
          chat: data.chat,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationPgVector = createAsyncThunk(
  // action type string
  "avatar/chat/pgvector",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_PG_VECTOR,
        variables: {
          chat: data.chat,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationGeneralChat = createAsyncThunk(
  // action type string
  "avatar/chat/generalchat",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_GENERAL_CHAT,
        variables: {
          chat: data.chat,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
          date_time: data.date_time,
          managed_by: data.managed_by,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAvatar = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateAvatar = await client.mutate({
        mutation: UPDATE_AVATAR,
        variables: {
          about_us: data.about,
          avatar_id: data.id,
          avatar_name: data.avatar_title,
          context: data.context,
          pod: data.pod_category,
          // prompt : data.prompt,
          avatar_image: data.image,
          version: data.version,
          avatar_owner: data.avatar_owner,
        },
      });
      return updateAvatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const loginEmailcheck = createAsyncThunk(
  // action type string
  "email/login",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateAvatar = await client.mutate({
        mutation: LOGIN_EMAIL_CHECK,
        variables: {
          user_id: data.user_id,
        },
      });
      return updateAvatar;
    } catch (error) {
      console.log(error);
      // useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const azureAdEmailCheck = createAsyncThunk(
  // action type string
  "email/login",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const azuread = await without_client.mutate({
        mutation: AZUREADEMAILCHECK,
        variables: {
          email: data,
        },
      });
      return azuread;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarFrequentlyPrompts = createAsyncThunk(
  // action type string
  "prompts/frequently",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const frequently = await client.mutate({
        mutation: AVATARFREQUENTLYPROMPTS,
        variables: {
          id: data.id,
          user_id: data.user_id,
          companyname: data.companyname,
        },
      });
      return frequently;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarHistoryTitle = createAsyncThunk(
  // action type string
  "prompts/historytitle",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const history_title = await client.mutate({
        mutation: AVATARHISTORYTITLE,
        variables: {
          id: data.id,
          user_id: data.user_id,
          companyname: data.companyname,
        },
      });
      return history_title;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarChatHistory = createAsyncThunk(
  // action type string
  "prompts/chathistory",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: AVATARCHATHISTORY,
        variables: {
          conversationid: data.conversationid,
          companyname: data.companyname,
        },
      });
      return chat_history;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomerDetails = createAsyncThunk(
  // action type string
  "prompts/chathistory",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: CUSTOMERDETAILS,
        variables: {
          email: data,
        },
      });
      return chat_history;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTokenRemaining = createAsyncThunk(
  // action type string
  "prompts/chathistory",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: GETTOKENREMAINING,
        variables: {
          company: data.company,
          avatar_id: data.avatar_id,
        },
      });
      return chat_history;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getKendraOpenAI = createAsyncThunk(
  // action type string
  "prompts/kendraopenai",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: KENDRA_OPENAI,
        variables: {
          prompts: data.prompts,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
          question: data.question,
        },
      });
      return chat_history;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarTagandMembersList = createAsyncThunk(
  // action type string
  "avatar/tagmemberslist",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const avatar_option_list = await client.mutate({
        mutation: AVATAR_TAG_MEMBERS_LIST,
        variables: {
          event_type: data.event_type,
          company: data.company,
          share_type: data.share_type,
          user_id: data.user_id,
          avatar_id: data.avatar_id,
        },
      });
      return avatar_option_list;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const shareAvatarConversion = createAsyncThunk(
  // action type string
  "avatar/share",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const avatar_share = await client.mutate({
        mutation: SHARE_NEW_AVATRA,
        variables: {
          share_type: data.share_type,
          user_id: data.user_id,
          user_session: data.user_session,
          selected_id: data.selected_id,
          title: data.title,
          company: data.company,
          avatar_id: data.avatar_id,
        },
      });
      return avatar_share;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const nsertNewFeedback = createAsyncThunk(
  // action type string
  "prompts/kendraopenai",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: INSERT_NEW_FEEDBACK,
        variables: {
          rating: data.rating,
          feedback: data.feedback,
          avatar_id: data.avatar_id,
          user_id: data.user_id,
          question_id: data.question_id,
          company: data.company,
        },
      });
      return chat_history;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listTagging = createAsyncThunk(
  // action type string
  "tag/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tag_list = await client.mutate({
        mutation: GET_TAG_LIST,
        variables: {
          compnayname: data.compnayname,
        },
      });
      return tag_list;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const insertTagging = createAsyncThunk(
  // action type string
  "tag/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tag_list = await client.mutate({
        mutation: INSERT_NEW_TAG,
        variables: {
          company: data.company,
          user_id: data.user_id,
          tag_title: data.tag_title,
          members_list: data.members_list,
          avatar_list: data.avatar_list,
        },
      });
      return tag_list;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTagging = createAsyncThunk(
  // action type string
  "tag/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tag_list = await client.mutate({
        mutation: UPDATE_TAG,
        variables: {
          id: data.id,
          company: data.company,
          user_id: data.user_id,
          tag_title: data.tag_title,
          members_list: data.members_list,
          avatar_list: data.avatar_list,
          user_type: data.user_type,
        },
      });
      return tag_list;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTagging = createAsyncThunk(
  // action type string
  "tag/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tag_list = await client.mutate({
        mutation: DELETE_TAG,
        variables: {
          id: data.id,
          company: data.company,
        },
      });
      return tag_list;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarList = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tag_list = await client.mutate({
        mutation: GET_AVALATAR_LIST,
        variables: {
          company: data.companyName,
        },
      });
      return tag_list;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updatehistory = createAsyncThunk(
  // action type string
  "prompts/kendraopenai",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history_edit = await client.mutate({
        mutation: HISTORY_EDIT,
        variables: {
          user_session: data.user_session,
          avatar_id: data.avatar_id,
          user_id: data.user_id,
          company: data.company,
          title: data.title,
        },
      });
      return chat_history_edit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deletehistory = createAsyncThunk(
  // action type string
  "prompts/kendraopenai",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history_delete = await client.mutate({
        mutation: HISTORY_DELETE,
        variables: {
          user_session: data.user_session,
          user_id: data.user_id,
          company: data.company,
        },
      });
      return chat_history_delete;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generateTableChart = createAsyncThunk(
  // action type string
  "prompts/tablechart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_graph = await client.mutate({
        mutation: GENERATE_TABLE_CHART,
        variables: {
          // user_session: data.user_session,
          html_string: data.html_string,
          chart_types: data.chart_types,
        },
      });
      return chat_graph;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserController = createAsyncThunk(
  // action type string
  "user/controller",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_graph = await client.mutate({
        mutation: USER_CONTROLLER,
        variables: {
          // user_session: data.user_session,
          company: data.company,
          user_id: data.user_id,
        },
      });
      return chat_graph;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getEmbeddingfile = createAsyncThunk(
  // action type string
  "user/file",
  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const file_embedding = await client.mutate({
        mutation: EMBEDDING_FILE,
        variables: {
          temp_session_id: data.user_session,
          company: data.company,
          unique_id: data.unique_id,
          avatar_id: data.avatar_id,
        },
      });
      return file_embedding;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getFilecheck = createAsyncThunk(
  // action type string
  "user/file",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const file_embedding = await client.mutate({
        mutation: EMBEDDING_FILE_CHECK,
        variables: {
          temp_session_id: data.user_session,
          company: data.company,
          unique_id: data.unique_id,
        },
      });
      return file_embedding;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPageination = createAsyncThunk(
  "recentprompts/list",

  async (data, { rejectWithValue }) => {
    try {
      const prompt_page = await client.mutate({
        mutation: GET_PAGEINATION,
        variables: {
          user_id: data.user_id,
          company: data.company,
        },
      });
      return prompt_page;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getRecentPrompts = createAsyncThunk(
  "recentprompts/list",

  async (data, { rejectWithValue }) => {
    try {
      const prompt_page = await client.mutate({
        mutation: GET_RECENT_PROMPTS,
        variables: {
          page: data.page,
          limit: data.limit,
          user_id: data.user_id,
          company: data.company,
        },
      });
      return prompt_page;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const gethistoryPageination = createAsyncThunk(
  "hitory/page",
  async (data, { rejectWithValue }) => {
    try {
      const history_pageination = await client.mutate({
        mutation: GET_PAGEINATION_HISTORY,
        variables: {
          user_id: data.user_id,
          company: data.company,
        },
      });
      return history_pageination;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getHistorylist = createAsyncThunk(
  "history/list",
  async (data, { rejectWithValue }) => {
    try {
      const history_page = await client.mutate({
        mutation: GET_HISTORY_LIST,
        variables: {
          page: data.page,
          limit: data.limit,
          user_id: data.user_id,
          company: data.company,
        },
      });
      return history_page;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pinnedAvatar = createAsyncThunk(
  "avatar/pinned",
  async (data, { rejectWithValue }) => {
    try {
      const pinned = await client.mutate({
        mutation: AVATAR_PINNED,
        variables: {
          avatar_id: data.avatar_id,
          company: data.company,
          user_id: data.user_id,
          pin_status: data.pin_status,
        },
      });
      return pinned;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listPinned = createAsyncThunk(
  "avatar/pinned",
  async (data, { rejectWithValue }) => {
    try {
      const pinned = await client.mutate({
        mutation: LIST_PINNED,
        variables: {
          company: data.company,
          user_id: data.user_id,
        },
      });
      return pinned;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getcreditavatar = createAsyncThunk(
  "avatar/credit/avatar",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: GET_CREDIT_MONTH,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
          user_type: data.user_type,
          company: data.company,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const tokencreditlist = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: TOKEN_CREDIT,
        variables: {
          company: data.companyName,
          year: data.year,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const tokencreditupdate = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: TOKEN_CREDIT_UPDATE,
        variables: {
          company: data.companyName,
          year: data.year,
          month: data.month_index,
          changed_value: data.current,
          existing_value: data.pre,
          user_id: data.user_id,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const tokencreditlog = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: TOKEN_LOG,
        variables: {
          company: data.companyName,
          year: data.year,
          month: data.month_index,
          user_id: data.user_id,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const agentlist = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: AGENT_LIST,
        variables: {
          company: data.companyName,
          year: data.year,
          month: data.month_index,
          user_id: data.user_id,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettokenagent = createAsyncThunk(
  "avatar/credit/avatar",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: GET_TOKEN_AGENT,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
          user_type: data.user_type,
          company: data.company,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const agentinsert = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: AGENT_LIST_INSERT,
        variables: {
          company: data.companyName,
          year: data.year,
          avatar_id: data.avatar_id,
          month: data.month,
          user_id: data.user_id,
          avatar_token: data.avatar_token,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getmodalchart = createAsyncThunk(
  "avatar/modal",
  async (data, { rejectWithValue }) => {
    try {
      const modalchart = await client.mutate({
        mutation: GET_MODAL_CHART,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
          user_type: data.user_type,
          company: data.company,
        },
      });
      return modalchart;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const agentupdate = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: AGENT_LIST_UPDATE,
        variables: {
          company: data.companyName,
          year: data.year,
          avatar_id: data.avatar_id,
          month: data.month,
          user_id: data.user_id,
          avatar_token: data.avatar_token,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const agentlog = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: AGENT_LOG,
        variables: {
          company: data.companyName,
          year: data.year,
          avatar_id: data.avatar_id,
          month: data.month,
          user_id: data.user_id,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const agentdelete = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: AGENT_DELETE,
        variables: {
          company: data.companyName,
          year: data.year,
          avatar_id: data.avatar_id,
          month: data.month,
          user_id: data.user_id,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const creditdaywise = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const creditday = await client.mutate({
        mutation: GET_CREDIT_DAY,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
          user_type: data.user_type,
          company: data.company,
        },
      });
      return creditday;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getpromptdaywise = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const creditday = await client.mutate({
        mutation: GET_PROMPT_DAY,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
          user_type: data.user_type,
          company: data.company,
        },
      });
      return creditday;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getnumberagents = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const creditday = await client.mutate({
        mutation: GET_NO_OF_AGENTS,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
          user_type: data.user_type,
          company: data.company,
        },
      });
      return creditday;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getallocatedtoken = createAsyncThunk(
  "token/allocation",
  async (data, { rejectWithValue }) => {
    try {
      const allocatedtoken = await client.mutate({
        mutation: GET_ALLOCATED_TOKEN,
        variables: {
          user_id: data.user_id,
          year: data.year,
          month: data.month,
          company: data.company,
          user_type: data.user_type,
        },
      });
      return allocatedtoken;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettokenrequest = createAsyncThunk(
  "token/tokenallocation",
  async (data, { rejectWithValue }) => {
    try {
      const tokenrequest = await client.mutate({
        mutation: GET_TOKEN_REQUEST,
        variables: {
          user_id: data.user_id,
          year: data.year,
          month: data.month,
          company: data.company,
          user_type: data.user_type,
        },
      });
      return tokenrequest;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettotalprompts = createAsyncThunk(
  "token/totalprompts",
  async (data, { rejectWithValue }) => {
    try {
      const totalprompts = await client.mutate({
        mutation: GET_TOTAL_PROMPTS,
        variables: {
          user_id: data.user_id,
          year: data.year,
          month: data.month,
          company: data.company,
          user_type: data.user_type,
        },
      });
      return totalprompts;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettopuser = createAsyncThunk(
  "token/totalprompts",
  async (data, { rejectWithValue }) => {
    try {
      const topuser = await client.mutate({
        mutation: GET_TOP_USER,
        variables: {
          user_id: data.user_id,
          year: data.year,
          month: data.month,
          company: data.company,
        },
      });
      return topuser;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getyearusage = createAsyncThunk(
  "token/yearusage",
  async (data, { rejectWithValue }) => {
    try {
      const yearusage = await client.mutate({
        mutation: GET_USAGE_YEAR,
        variables: {
          user_id: data.user_id,
          year: data.year,
          company: data.company,
          user_type: data.user_type,
        },
      });
      return yearusage;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarS3DocumentList = createAsyncThunk(
  "avatar/doclist",
  async (data, { rejectWithValue }) => {
    try {
      const yearusage = await client.mutate({
        mutation: S3_DOCUMENT_LIST,
        variables: {
          avatar_id: data.avatar_id,
          company_name: data.company_name,
        },
      });
      return yearusage;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const publiclistAvatar = createAsyncThunk(
  // action type string
  "private/avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatarprivate = await client.mutate({
        mutation: PUBLIC_LIST_AVATAR,
        variables: {
          event_type: data.event_type,
        },
      });
      return onboardavatarprivate;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const historyrecordlist = createAsyncThunk(
  // action type string
  "private/avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatarprivate = await client.mutate({
        mutation: HISTORY_RECORD_LIST,
        variables: {
          event_type: data.event_type,
          avatar_id: data.id,
        },
      });
      return onboardavatarprivate;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// HISTORY_RECORD_LIST_HEALTH
export const historyrecordlistofhealth = createAsyncThunk(
  // action type string
  "private/avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatarprivatehealth = await client.mutate({
        mutation: HISTORY_RECORD_LIST_HEALTH,
        variables: {
          event_type: data.event_type,
          avatar_id: data.id,
        },
      });
      return onboardavatarprivatehealth;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const historyrecordviewlist = createAsyncThunk(
  // action type string
  "private/avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatarprivate = await client.mutate({
        mutation: HISTORY_RECORD_VIEW_LIST,
        variables: {
          event_type: data.event_type,
          user_session: data.user_session,
        },
      });
      return onboardavatarprivate;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generatedQSearchURL = createAsyncThunk(
  // action type string
  "qsearch/generatedurl",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const count = await without_client.mutate({
        mutation: GENERATEDQSEARCHURL,
        variables: {},
      });
      return count;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const mailboxsend = createAsyncThunk(
  // action type string
  "prompts/chathistory/mail",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: CHAT_RESPONCE_EMAIL,
        variables: {
          subject: data.subject,
          to: data.to,
          body: data.body,
        },
      });
      return chat_history;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
